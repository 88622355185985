const query = (id, isPreview) => `
{
  imageWithText(id: "${id}", preview: ${isPreview}) {
    heading
    description
    isReversed
    elementId
    showBackground
    variant
    image {
      title
      description
      url
      width
      height
    }
    backgroundImage {
      title
      description
      url
      width
      height
    }
    link {
      label
      title
      url
      target
      isAnchorTag
      scrollToElementId
      gaEventClass
      gaEventLabel
      image {
        title
        description
        url
        width
        height
      }
    }
  }
}

`
module.exports = { query }
