export const bannerProps = {
    
    "heading": "Philadelphia Original Portions (24 x 16.7g)",
    "description": "The fresh, creamy taste and smooth, consistent texture of Philadelphia Original makes it the ideal ingredient for dishes across your entire menu. Made with pasteurised milk and with no added preservatives. Suitable for vegetarians.",
    "backLink": {
      "name": "Range Page Link",
      "url": "/range/",
      "label": "Back",
      "title": "Back",
      "target": "_self",
      "isAnchorTag": null,
      "scrollToElementId": null,
      "imageType": "regular",
      "gaEventClass": "event_button_click",
      "gaEventLabel": "Back"
    },
    "style": {
      heading: "text-3xl text-white text-center heroText mt-16 font-ProxNarrow tracking-wide",
      bannerContent: "relative",
      image:"image w-full",
      txtcontent:"",
      txtHead: "text-3xl mb-1 text-white text-center absolute top-[25%] left-[50%] translate-x-[-50%] translate-y-0",
      txtDesc: "text-[15px] max-w-full w-[750px] leading-[30px] my-0 mx-auto lg:mt-0 mt-30 text-center px-8 absolute bottom-[-75%] text-[#00329f] text-center left-[50%] translate-x-[-50%] translate-y-0",
      textLink: "inline-block leading-7 relative text-white align-middle ",
      imgWrapper:"w-full flex justify-center",
      imgParent:"lg:relative lg:h-[428px] md:h-[500px] h-[300px]",
      imgBg:"bg-[#00329f] absolute top-0 left-0 w-full lg:h-full h-[12%] z-[-1]",
      imageResponsive:"w-[600px] m-w-full absolute top-[41%] left-[50%] translate-x-[-50%] translate-y-0"  ,
      linkWrpr:"",
      backBtn: "w-[200px] lg:bg-transparent absolute lg:text-white lg:left-[45%] left-[25%] lg:max-w-full lg:top-[4%] top-0 clear-both my-0 mx-auto px-0 lg:py-8 py-30  lg:text-left text-white w-full text-center z-[2] heroShadow tracking-[1px]"
    },
    "foregroundImage": {
      url: "http://images.sweetauthoring.com/product/98222.png",
      alt: "Philadelphia Original Portions (24 x 16.7g)"
    }
  }

