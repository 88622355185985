export const inputProps={
    "type": "text",
    "label": "Input Field",
    "id": "inputfield1",
    "name": "inputfield1",
    "placeholder": "Input Field 1",
    "isRequired": true,
    "errorMessage": "",
    "onChange": function() {console.log('Input changed')},
    "button": {
        id: "",
        name: ""
    },
    "onBlur": function() {console.log('Input Focus')},
    "isValid": true,
    "maxLength": 20,
    "value": "",
    "style": {
        inputContainer: "lg:mb-3 mb-27 xl:w-96",
        error: "border-4 border-red-500",
        labelStyle: "inline-block p-10 text-lg leading-5 text-black  ",
        text: "form-control block w-full p-14 text-base font-normal text-gray-700  bg-white bg-clip-padding  border border-solid  rounded-[25px]  border-solid border-2 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ",
        select:"form-select bg-no-repeat bg-right appearance-none block w-full p-14 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat  border border-solid  rounded-[25px] transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none bg-[94% center]  bg-[0% 96%]",
        chkWrpr: "flex mb-20",
        checkBox:"form-check-input h-33 w-33 border  rounded-md bg-white checked:bg-darkBlue checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
        checkBoxText: "inline-block p-5 text-sm leading-5 text-white lg:w-[400px]",
    }
}