export const props =  {
    "logo": {
      "url": "https://images.ctfassets.net/qc3lz96t0r4x/YdKkE9Usl4CsL6YPrP957/cdec9cf3a2c5e94ac0041deff2417c6b/philadelphia-professional-logo.png",
      "title": "Philadelphia Logo",
      "description": "Philadelphia Logo",
      "width": 360,
      "height": 99
    },
    "navigationCollection": {
      "items": [
        {
          "label": "THE PHILLY DIFFERENCE",
          "link": {
            "label": "THE PHILLY DIFFERENCE",
            "title": null,
            "url": "/phillydifference/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "image": null
          }
        },
        {
          "label": "THE RANGE",
          "link": {
            "label": "THE RANGE",
            "title": null,
            "url": "/range/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "image": null
          }
        },
        {
          "label": "THE RECIPES",
          "link": {
            "label": "THE RECIPES",
            "title": null,
            "url": "/recipes/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "image": null
          }
        },
        {
          "label": "THE PHILLY FIVE",
          "link": {
            "label": "THE PHILLY FIVE",
            "title": null,
            "url": "/the-philly-five/education/",
            "target": "_self",
            "isAnchorTag": true,
            "scrollToElementId": null,
            "image": null
          }
        }
      ]
    }
  }