import React from "react"
import Video from "../UI/Video/Video"
import PropTypes from "prop-types"
import * as styles from "./ArticleVideo-tw-styles"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"

const ArticleVideo = props => {
  const { heading, description, video, isContentReversed, variant, link } =
    props
  const style = styles[variant] || styles.defaultStyle

  return (
    <div
      className={`${style?.aVContainer} ${
        isContentReversed ? "flex flex-col-reverse" : ""
      }`}
    >
      <div>{video && <Video {...video} style={style} />}</div>
      <div className={style?.aVcontent}>
        {heading && (
          <Typography className={style?.aVcontentHeading} content={heading} />
        )}

        {description && (
          <Typography content={description} className={style?.description} />
        )}
        {link && <Link {...link} />}
      </div>
    </div>
  )
}

ArticleVideo.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.object,
  isContentReversed: PropTypes.bool,
  variant: PropTypes.string,
  link: PropTypes.object,
}

export default ArticleVideo
