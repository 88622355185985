import React from 'react'
import {screen, render} from '@testing-library/react'
import ArticleVideo from './ArticleVideo'
import {ArticleVideoProps} from '../../mocks/ArticleVideo.mock'

describe('ArticleVideo', () => { 
    render(<ArticleVideo {...ArticleVideoProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<ArticleVideo {...ArticleVideoProps}/>)
    })

    test('should render Header', () => {
        expect(screen.getAllByText("Article Video"))  
    })

    test('should render Description', () => {
        expect(screen.getAllByText("It is time to get to know the UK no.1 cream cheese"))  
    })

})