import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { get } from "../../../helpers/utils"
import Typography from "../../UI/Typography/Typography"
import Image from "../../UI/Image/Image"
import Slider from "react-slick"
import { RECIPES_SLIDER as style } from "./RecipeCardSlider-tw-styles"
import {
  dotsLabel,
  gaInternalLink,
  gaLeftlabel,
  gaRightlabel,
  gaSliderClass,
} from "../../../constants/gtm.constants"
import { imageConstants } from "../../../constants/image.constants"
import { onSlickDotUpdate } from "../../../helpers/slider"

const SampleArrow = ({
  currentSlide,
  gaeventlabel,
  slideCount,
  onClick,
  dotsListId,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`${props?.className} ${gaSliderClass}`}
      data-action-detail={gaeventlabel}
      onClick={() => {
        onClick()
        onSlickDotUpdate(dotsListId)
      }}
    >
      <Image contentfulImage={imageConstants.arrowRight} />
    </button>
  )
}
SampleArrow.propTypes = {
  gaeventlabel: PropTypes.string,
  className: PropTypes.any,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  onClick: PropTypes.func,
  dotsListId: PropTypes.number,
}

const RecipeClassSlider = props => {
  const { heading, recipeClassesCollection, onRecipeCardClick } = props
  const recipeClasses = get(recipeClassesCollection, "items", [])
  const [draging, setDragging] = useState(false)
  const [imgHoverClass, setImgHoverClass] = useState("")
  const [activeCard, setActiveCard] = useState("")

  const dotsListId = Math.round(Math.random() * 1000000)
  const slidesToshow = {
    desktop: 3.2,
    tab: 2.2,
    mobile: 1.2,
  }

  const slidesToScroll = {
    desktop: 3,
    tab: 2,
    mobile: 1,
  }

  const sliderSettings = {
    infinite: false,
    arrows: true,
    swipeToSlide: false,
    dots: true,
    speed: 500,
    slidesToShow: slidesToshow.desktop,
    slidesToScroll: slidesToScroll.desktop,
    appendDots: dots => (
      <ul
        id={dotsListId}
        onClick={() => {
          onSlickDotUpdate(dotsListId, slidesToshow.desktop)
        }}
      >
        {dots}
      </ul>
    ),
    responsive: [
      {
        breakpoint: 940,
        settings: {
          slidesToShow: slidesToshow.tab,
          slidesToScroll: slidesToScroll.tab,
          appendDots: dots => (
            <ul
              id={dotsListId}
              onClick={() => {
                onSlickDotUpdate(dotsListId, slidesToshow.tab)
              }}
            >
              {dots}
            </ul>
          ),
        },
      },
      {
        breakpoint: 614,
        settings: {
          slidesToShow: slidesToshow.mobile,
          slidesToScroll: slidesToScroll.mobile,
          appendDots: dots => (
            <ul
              id={dotsListId}
              onClick={() => {
                onSlickDotUpdate(dotsListId, slidesToshow.mobile)
              }}
            >
              {dots}
            </ul>
          ),
        },
      },
    ],

    nextArrow: (
      <SampleArrow gaeventlabel={gaRightlabel} dotsListId={dotsListId} />
    ),
    prevArrow: (
      <SampleArrow gaeventlabel={gaLeftlabel} dotsListId={dotsListId} />
    ),
    customPaging: index => {
      return (
        <button className={gaSliderClass} data-action-detail={dotsLabel}>
          {index + 1}
        </button>
      )
    },
  }

  // observe drag start and end events
  const handleBeforeChange = useCallback(() => {
    setDragging(true)
  }, [setDragging])

  const handleAfterChange = useCallback(() => {
    setDragging(false)
  }, [setDragging])

  const onMouseEnter = idx => {
    setActiveCard(idx)
    setImgHoverClass("scale-110 transiImg")
  }
  const onMouseLeave = () => {
    setActiveCard("")
    setImgHoverClass("")
  }

  // build slides
  const buildSlides = () => {
    return (
      recipeClasses?.length &&
      recipeClasses.map((item, index) => {
        return (
          <div
            className={style?.sliderContnr}
            key={index}
            onClick={e => {
              onRecipeCardClick(e, item, draging)
            }}
          >
            <Image
              contentfulImage={item.recipeImage}
              eventLabel={item.text}
              eventClass={gaInternalLink}
              className={activeCard === index ? imgHoverClass : ""}
            />
            <p>{item.text}</p>
            <span
              className={style?.gradTop}
              onMouseEnter={() => {
                onMouseEnter(index)
              }}
              onMouseLeave={() => {
                onMouseLeave(index)
              }}
            ></span>
          </div>
        )
      })
    )
  }

  return (
    <div className={style?.sliderContainer}>
      {heading && (
        <Typography content={heading} className={style.receipeListingHeading} />
      )}
      <Slider
        {...sliderSettings}
        className={style?.slider}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
      >
        {buildSlides()}
      </Slider>
    </div>
  )
}

RecipeClassSlider.propTypes = {
  heading: PropTypes.string,
  recipeClassesCollection: PropTypes.object,
  onRecipeCardClick: PropTypes.func,
}

export default RecipeClassSlider
