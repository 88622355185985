const query = (id, isPreview) => `{
  imageCarousel(id: "${id}", preview: ${isPreview}) {
    variant
    slidesCollection(limit: 10) {
      items {
        title
        text
        variant
        cardsCollection(limit: 5) {
          items {
            title
            text
            variant
            image {
              url
              title
              description
              width
              height
            }
          }
        }
        link {
          label
          title
          url
          target
          isAnchorTag
          scrollToElementId
          image {
            title
            description
            url
            width
            height
          }
          gaEventClass
          gaEventLabel
        }
        isContentReversed
      }
    }
    backgroundImage {
      title
      description
      url
      width
      height
    }
  }
}

  `
module.exports = { query }
