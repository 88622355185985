import React, { useState, useEffect } from "react"
import axios from "axios"
import PropTypes from "prop-types"
import ImageLayout from "../ImageLayout/ImageLayout"
import ImageCardsLayout from "../ImageCardsLayout/ImageCardsLayout"
//import { buildImageCardsObject } from "../../helpers/BuildImageCardObject"
import { labels, MEDIA_PHOTO_ID } from "../../constants/label.constants"
import { mondelezAPI } from "../../constants/mondelezAPI.contants"
import { gaImageClick} from "../../constants/gtm.constants"

const RecipeListingApi = props => {
  const {
    heading,
    description,
    methodName,
    variant,
    methodType,
    sectorId,
    categoryId,
    slidersSettings,
  } = { ...props }
  const [recipeList, getRecipeList] = useState([])

  const baseUrl=process.env.GATSBY_RECIPE_URL

  const headersValue = {
    appid: process.env.GATSBY_APP_ID,
    "Content-Type": "application/json",
  }
  const setResponseData = (response, variant = "IMAGE_TEXT_BOX") => {
    response &&
      getRandomRecipeCards(response?.data?.RecipeSearchResults, variant)
  }
  useEffect(() => {
    async function fetchData() {
      if (methodName === "getSimilarRecipeResults") {
        await axios
          .post(`${baseUrl}/${methodName}`, 
          {
            "languageId": mondelezAPI.languageId,
            "websiteId": mondelezAPI.websiteId,
            "sortFrom": 1,
            "sortTo": 1,
            "pageNumber": 1,
            "pageSize": 50,
            "categoryID": categoryId,
            "sectorID": sectorId,
            "keyword": "null",
            "includeFilter": "_default",
            "excludeFilter": "_default"
          },
          { headers: headersValue}
          )
          .then(response => {
            setResponseData(response)
          })
          .catch(error => {
            console.log(methodName, ":: Error :: ", error.message)
          })
        } else {
        await axios
          .post(
            `${baseUrl}/${methodName}`,
            {
              "languageId": mondelezAPI.languageId,
              "websiteId": mondelezAPI.websiteId,
              "sortFrom": 1,
              "sortTo": 1,
              "pageNumber": 1,
              "pageSize": 50,
              "noOfRecords": 6
            },
            { headers: headersValue }
          )
          .then(response => setResponseData(response, labels.imageTextBox))
          .catch(error => {
            console.log(methodName, ":: Error :: ", error.message)
          })
      }
    }
    fetchData()
  }, [])

  const getImageUrl = photoTypeID => {
    if(Array.isArray(photoTypeID)) {
      const filterImage =  photoTypeID?.find(
        item => item.Type === MEDIA_PHOTO_ID
      )
      return filterImage?.Url|| ""     
    } else {    
      return photoTypeID
    }
  }

  const buildImageCardsObject = (recipesData, variant) => {
    let cards = [];
    recipesData?.map((data, index) => {
      if (index < 6) {
        cards.push({
          title: data.RecipeName,
          variant: variant,
          image: {
            url: getImageUrl(data.PhotoURL),
            title: data.RecipeName,    
          },
          link: {
            url: `/recipes/${data.SeoPageName}/`,
            gaEventClass: gaImageClick,
            gaEventLabel: data.RecipeName
          },
          gaEventClass: gaImageClick,
          gaEventLabel: data.RecipeName      
        })
      }
    })
    return cards
  }
  
  const getRandomRecipeCards = (recipesData, variant) => {
    if (recipesData) {
      const imageCards =
        recipesData?.length > 0 && buildImageCardsObject(recipesData, variant)
      imageCards && getRecipeList(imageCards)
    }
  }

  return (
    <>
      {recipeList && variant == labels.imageLayout ? (
        <ImageLayout
          heading={heading}
          description={description}
          imagesCollection={recipeList}
        ></ImageLayout>
      ) : (
        recipeList &&
        variant == labels.imageCardsLayout && (
          <ImageCardsLayout
            title={heading}
            isSlider={true}
            sliderSettings={slidersSettings}
            variant={labels.imageTextBoxLayout}
            carditems={recipeList}
          ></ImageCardsLayout>
        )
      )}
    </>
  )
}

export default RecipeListingApi

RecipeListingApi.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  variant: PropTypes.string,
  methodName: PropTypes.string,
  methodType: PropTypes.string,
  sectorId: PropTypes.string,
  categoryId: PropTypes.string,
  slidersSettings: PropTypes.object,
}
