const  algoliasearch = require('algoliasearch')

// search recipes
const getSearchResults = async (recipesListData={}) => {
    const {search, filtersArr, pageNo, sortOption} = recipesListData
    try {
        const indices = {
            "last_modified_date desc":process.env.GATSBY_ALGOLIA_RECIPES_SECONDARY_INDEX_NAME,
            "title asc" : process.env.GATSBY_ALGOLIA_RECIPES_PRIMARY_INDEX_NAME
        }
        
        const indexName = indices[sortOption] || process.env.GATSBY_ALGOLIA_RECIPES_SECONDARY_INDEX_NAME
        const term = search || ''
        const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY)
        const index = client.initIndex(indexName)
        const facetFilters = filtersArr?.map(item => `Classifications.Id: ${item.CategoryId}`)
        const options = {
            ... (facetFilters.length && {facetFilters: facetFilters}),
            page:pageNo,
            hitsPerPage: 9
        }
        const result = await index.search(term, {
            ...options
        })
        return result
    } catch (error) {
        return {error: error.message}
    }   
}

module.exports = {getSearchResults}
