export const IngredientProps = {
    "updateQty": function updateqty() {console.log('Update Qty')},
    "data": {
        "image": {
            url: "https://images.sweetauthoring.com/recipe/398288_2106.png",
            width: 360,
            height: 360,
            "title": "Ingredients",
        },
        "servings": 10,
        "ingredientList": [
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 200,
                "Name": "ginger",
                "OrderId": 0,
                "Quantity": 25,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 18,
                "Name": "garlic",
                "OrderId": 1,
                "Quantity": 25,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 52210,
                "Name": "coconut milk",
                "OrderId": 2,
                "Quantity": 160,
                "ShortName": null,
                "Unit": "ml"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 79,
                "AisleId": 14,
                "BrandId": 166,
                "Name": "Philadelphia Original",
                "OrderId": 3,
                "ProductId": 95,
                "Quantity": 500,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 2956,
                "Name": "curry powder",
                "OrderId": 4,
                "PreText": "medium",
                "Quantity": 25,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 106191,
                "Name": "Scotch bonnet",
                "OrderId": 5,
                "Quantity": 3,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 83,
                "Name": "oil",
                "OrderId": 6,
                "Quantity": 15,
                "ShortName": null,
                "Unit": "ml"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 16139,
                "Name": "shallots",
                "OrderId": 7,
                "PostText": ", finely diced",
                "PreText": "banana",
                "Quantity": 2,
                "ShortName": null
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 106189,
                "Name": "mustard seeds",
                "OrderId": 8,
                "Quantity": 15,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 106192,
                "Name": "miso",
                "OrderId": 9,
                "PreText": "white",
                "Quantity": 15,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 27464,
                "Name": "carrots",
                "OrderId": 10,
                "PostText": ", diced",
                "Quantity": 600,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 18307,
                "Name": "sweet potatoes",
                "OrderId": 11,
                "PostText": ", diced",
                "Quantity": 400,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 108,
                "Name": "vegetable stock",
                "OrderId": 12,
                "Quantity": 600,
                "ShortName": null,
                "Unit": "ml"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 18339,
                "Name": "cherry tomatoes",
                "OrderId": 13,
                "PostText": ", diced",
                "Quantity": 300,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 22,
                "Name": "butternut squash",
                "OrderId": 14,
                "PostText": ", sliced on mandolin",
                "Quantity": 600,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 178,
                "Name": "maple syrup",
                "OrderId": 15,
                "Quantity": 15,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 3116,
                "Name": "coconut",
                "OrderId": 16,
                "PreText": "desiccated",
                "Quantity": 15,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 15,
                "Name": "olive oil",
                "OrderId": 17,
                "Quantity": 15,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 12316,
                "Name": "red chilli",
                "OrderId": 18,
                "PostText": ", Julienne",
                "Quantity": 1,
                "ShortName": null
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 17005,
                "Name": "spring onions",
                "OrderId": 19,
                "PostText": ", finely sliced",
                "Quantity": 3,
                "ShortName": null
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 54295,
                "Name": "cress",
                "OrderId": 20,
                "PostText": " (optional)",
                "PreText": "baby",
                "Quantity": 50,
                "ShortName": null,
                "Unit": "g"
            },
            {
                "__type": "RecipeIngredient:#Sweet.RecipeDb.Webservice.CompositeTypes",
                "Id": 42,
                "Name": "lime",
                "OrderId": 21,
                "PostText": " wedges (optional)",
                "ShortName": null
            }
        ]
    },
}