import React, { useState, useEffect } from "react"
import { styles, imageStyle, videoSliderStyles } from "./VideoSlider-tw-styles"
import Image from "../UI/Image/Image"
import PropTypes from "prop-types"
import VideoPlayer from "../UI/Video/VideoPlayer"
import Typography from "../UI/Typography/Typography"
import CardSlider from "../UI/CardSlider/CardSlider"
import ImageWithText from "../ImageWithText/ImageWithText"
import RichTextModel from "../RichTextModel/RichTextModel"
import useScreenSize from "../../hooks/useScreenSize"

const VideoSlider = props => {
  const {
    heading,
    elementId,
    description,
    backgroundImage,
    leftBackgroundImage,
    rightBackgroundImage,
    videosCollection,
    isSlider,
    sliderRichText,
    mobileLeftBackgroundImage,
    mobileRightBackgroundImage,
  } = props
  const screen = useScreenSize()
  const videos = videosCollection && videosCollection?.items
  const {
    videoSliderWrpr,
    videoSliderDesc,
    sliderThumbContainer,
    videoSLiderHeading,
  } = styles
  const [current, setCurrent] = useState(0)

  const onSliderUpdates = index => {
    setCurrent(index)
  }
  const leftImage =
    screen?.isMobile || screen?.isTablet
      ? mobileLeftBackgroundImage
      : leftBackgroundImage
  useEffect(() => {
    var tag = document.createElement("script")
    tag.src =
      "https://www.youtube.com/s/player/e5f6cbd5/www-widgetapi.vflset/www-widgetapi.js"
    var firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  }, [])

  return (
    <>
      <div className="relative tpf-education">
        <div className="absolute w-full videoBg">
          <div className="lg:max-w-[17%]  lg:top-0 lg:left-0 lg:absolute w-[250px] mx-auto my-0 videoLeftImg">
            {leftImage && (
              <Image
                className="lg:h-auto h-[155px]"
                contentfulImage={leftImage}
              ></Image>
            )}
          </div>
          {screen?.isDesktop && (
            <div className="max-w-[17%] top-0 right-0 absolute ">
              {rightBackgroundImage && (
                <Image
                  className=""
                  contentfulImage={rightBackgroundImage}
                ></Image>
              )}
            </div>
          )}
        </div>

        <div>
          <section className={videoSliderWrpr}>
            <div className="px-10 md:px-30 lg:px-0 lg:pt-[72px] pt-[150px]">
              <div className="">
                <Typography className={videoSLiderHeading} content={heading} />
                <Typography className={videoSliderDesc} content={description} />
              </div>
              <div
                className="main-slider-container min-h-[380px] md:min-h-[460px] lg:min-h-[480px]"
                id={elementId}
              >
                {isSlider
                  ? videos?.map((video, index) => {
                      return (
                        <div
                          className={
                            index === current
                              ? "video active transitonINCls lg:px-0 absolute top-0"
                              : "video invisible transitonOutCls absolute top-0"
                          }
                          key={index}
                        >
                          <div>
                            {video.isOnlyImage ? (
                              <ImageWithText style={imageStyle} {...video} />
                            ) : (
                              <VideoPlayer
                                style={videoSliderStyles}
                                {...video}
                                activeVideo={index === current}
                              />
                            )}
                          </div>
                        </div>
                      )
                    })
                  : videos &&
                    videos.length > 0 && (
                      <ImageWithText style={imageStyle} {...videos[0]} />
                    )}
              </div>

              {isSlider && (
                <div className="overflow-hidden">
                  <CardSlider
                    className={sliderThumbContainer}
                    arrows={true}
                    infinite={true}
                    isVideoCarousel={true}
                    variant="phillyfive"
                    onSliderUpdate={onSliderUpdates}
                  >
                    {videos?.map((video, index) => {
                      return (
                        <div key={index} className="slider">
                          {video.isOnlyImage ? (
                            <Image contentfulImage={video.image} />
                          ) : (
                            <Image contentfulImage={video.posterImage} />
                          )}
                        </div>
                      )
                    })}
                  </CardSlider>
                </div>
              )}
            </div>
          </section>
          <RichTextModel style={styles} {...sliderRichText} />
        </div>
        {(screen?.isMobile || screen?.isTablet) && (
          <div className="w-[285px] mx-auto my-0">
            {mobileRightBackgroundImage && (
              <Image
                className=""
                contentfulImage={mobileRightBackgroundImage}
              ></Image>
            )}
          </div>
        )}
      </div>
    </>
  )
}

VideoSlider.propTypes = {
  heading: PropTypes.string,
  elementId: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.object,
  leftBackgroundImage: PropTypes.object,
  rightBackgroundImage: PropTypes.object,
  videosCollection: PropTypes.object,
  isSlider: PropTypes.bool,
  sliderRichText: PropTypes.object,
  mobileLeftBackgroundImage: PropTypes.object,
  mobileRightBackgroundImage: PropTypes.object,
}

export default VideoSlider
