import React from 'react'
import {screen, render} from '@testing-library/react'
import Sitemap from './Sitemap'
import {props} from '../../mocks/Sitemap.mock'

describe('Sitemap', () => { 
    render(<Sitemap {...props}/>)
    let view = null
    beforeEach(() => {
        view = render(<Sitemap {...props}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText(/Sitemap/i)).toBeInTheDocument;
    })
    test('should render All Links', () => {   
        expect(screen.getByText(/THE PHILLY DIFFERENCE/i).href).toBe("http://localhost/phillydifference/")
        expect(screen.getByText(/THE RANGE/i).href).toBe("http://localhost/range/")
        expect(screen.getByText(/THE PHILLY FIVE/i).href).toBe("http://localhost/the-philly-five/education/")
        expect(screen.getByText(/CORPORATE INFORMATION/i).href).toBe("http://localhost/corporate-information/company-details/")
    })  
 })