const query = (id, isPreview) => `
{
  richTextModel(id: "${id}", preview: ${isPreview}) {
        name
        variant
        title
        richText{
          json
          links {
            entries {
              inline {
                sys{
                  id
                }
                __typename
                ... on SuperScript {
                  superScript
                }
                ... on SubScript{
                  subScript
                }
                ... on Link {
                  label
                  url
                  target
                }
              }
            }
          }    
        }
        link {
          label
          title
          url
          target
          isAnchorTag
          scrollToElementId
          gaEventClass
          gaEventLabel
        }
  }
}
`
module.exports = { query }
