import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "../UI/Button/Button"
import { TOGGLE_PASSWORD } from "../../constants/rules.constants"
import RenderField from "../../helpers/RenderField"
import { validate } from "../../helpers/FormValidations"

const Registration = props => {
  const { heading, fields, termsText, button, note, checkbox } = props
  const [form, setForm] = useState(fields)
  const [hidePassword, setHidePassword] = useState(true)
  const [error, setError] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmEmail: "",
    confirmPassword: "",
    confirmationCheckbox: "",
  })

  const handleChange = (event, id) => {
    if (id === TOGGLE_PASSWORD) {
      setHidePassword(!hidePassword)
    } else {
      const validateField = validate({ ...form[id], value: event.target.value })
      const validateCheckbox = validate({
        ...checkbox[id],
        value: event.target.checked,
      })
      setError(err => ({
        ...err,
        [validateField.name]: validateField.error,
        [validateCheckbox.name]: validateCheckbox.error,
      }))
      const updateValues = Object.entries(form).map(([key, field]) => {
        if (key === id) {
          return {
            ...field,
            value: event.target.value,
          }
        } else if (field.fieldType === "checkbox") {
          return {
            ...field,
            value: event.target.checked,
          }
        } else {
          return field
        }
      })
      setForm(updateValues)
    }
  }

  function Validate(orgObj, tempObj) {
    return Object.entries(orgObj).map(([key, value]) => {
      tempObj[key] = validate(value)
      setError(err => ({ ...err, [value.name]: value.error }))
      return tempObj[key].isValid
    })
  }

  const handleSubmit = () => {
    const validateField = form
    const validateCheckbox = checkbox
    const isfieldsValid = Validate(form, validateField)
    const isCheckBoxValid = Validate(checkbox, validateCheckbox)
    if (!isfieldsValid.includes(false) && !isCheckBoxValid.includes(false)) {
      //submit api call
    }
  }

  function renderFields(list) {
    return Object.entries(list).map(([key, value]) => (
      <RenderField
        key={key}
        id={key}
        field={value}
        onBlur={handleChange}
        handleChange={handleChange}
        hidePassword={hidePassword}
        error={error}
      />
    ))
  }

  return (
    <div>
      {heading && <div>{heading}</div>}
      <div className="fields-section">{renderFields(form)}</div>
      {note && <p>{note}</p>}
      {error.confirmationCheckbox && <div>{error.confirmationCheckbox}</div>}
      <div className="checkbox-section">{renderFields(checkbox)}</div>
      {button && (
        <Button text={button.label} type="secondary" onClick={handleSubmit} />
      )}
      {termsText && <div>{termsText}</div>}
    </div>
  )
}

Registration.propTypes = {
  heading: PropTypes.string,
  fields: PropTypes.object,
  termsText: PropTypes.string,
  button: PropTypes.object,
  note: PropTypes.string,
  checkbox: PropTypes.object,
}

export default Registration
