import React, { useState, useEffect, useRef } from "react"
import Slider from "react-slick"
import PropTypes, { element } from "prop-types"
import Image from "../Image/Image"
import { imageConstants } from "../../../constants/image.constants"
import {
  gaLeftlabel,
  gaRightlabel,
  gaSliderClass,
  dotsLabel,
} from "../../../constants/gtm.constants"

const SampleArrow = ({ currentSlide, slideCount, ...props }) => {
  const { eventlabel } = props
  return (
    <button
      {...props}
      className={`${props?.className} ${gaSliderClass}`}
      data-action-detail={eventlabel}
    >
      <Image contentfulImage={imageConstants.arrowRight} />
    </button>
  )
}

SampleArrow.propTypes = {
  eventlabel: PropTypes.string,
  className: PropTypes.any,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
}

const CardSlider = props => {
  const {
    dots,
    arrows,
    infinite,
    slidesToShow,
    speed,
    children,
    slidesToScroll,
    onSliderUpdate = {},
    isVideoCarousel,
    hideArrowAtSlide,
    IsCenterMode,
  } = props

  const [arrowClick, changeArrow] = useState(0)

  var settings = {
    dots: dots || true,
    infinite: infinite != undefined ? infinite : false,
    speed: speed || 500,
    ...(slidesToShow && { slidesToShow: slidesToShow }),
    ...(slidesToScroll && { slidesToScroll: slidesToScroll }),
    arrows: arrows != undefined ? arrows : false,
    swipeToSlide: true,
    variableWidth: true,
    centerMode: IsCenterMode != undefined ? IsCenterMode : true,
    ...(arrows && { nextArrow: <SampleArrow eventlabel={gaRightlabel} /> }),
    ...(arrows && { prevArrow: <SampleArrow eventlabel={gaLeftlabel} /> }),
    beforeChange: (current, next) => {
      changeArrow(next)
      isVideoCarousel && onSliderUpdate(next)
    },
    // added event class and event label for gtm to dots
    customPaging: index => {
      return (
        <button className={gaSliderClass} data-action-detail={dotsLabel}>
          {index + 1}
        </button>
      )
    },
  }

  const getPrevVal = prev => {
    if (arrowClick == 0 && prev && prev.length > 0) {
      return "hidden"
    }
    return "visible"
  }

  const getNextVal = (next, lngChildren) => {
    if (arrowClick == lngChildren - 1 && next && next.length > 0) {
      return "hidden"
    }
    return "visible"
  }

  useEffect(() => {
    if (arrows && hideArrowAtSlide) {
      var next = document.getElementsByClassName("slick-next")
      var prev = document.getElementsByClassName("slick-prev")
      const lngChildren = children && children.length
      prev[0].style.visibility = getPrevVal(prev)
      next[0].style.visibility = getNextVal(next, lngChildren)
    }
  }, [arrowClick])

  useEffect(() => {
    var sliderdivs = document.getElementsByClassName("slick-track")
    const sliderdiv = [...sliderdivs]

    sliderdiv &&
      sliderdiv.length > 0 &&
      sliderdiv.map(eachdiv => {
        const parentWithClass = eachdiv.closest(".videoSlider")
        if (parentWithClass != null) {
          const sliderele =
            parentWithClass.getElementsByClassName("slick-track")
          const innersliderdiv = sliderele?.length && sliderele[0]?.children
          const clickdivs = [...innersliderdiv]
          clickdivs?.length &&
            clickdivs?.map(clickdiv => {
              clickdiv?.addEventListener("click", event => {
                handleOnClick(clickdiv)
              })
            })
        }
      })
  }, [])

  const handleOnClick = element => {
    let clikedindex = element.getAttribute("data-index")
    sliderRef?.current?.slickGoTo(clikedindex==children+1?1:clikedindex)
  }

  const sliderRef = useRef()
  return (
    <Slider {...settings} className={"videoSlider"} ref={sliderRef}>
      {children}
    </Slider>
  )
}

CardSlider.propTypes = {
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  infinite: PropTypes.bool,
  slidesToShow: PropTypes.number,
  speed: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slidesToScroll: PropTypes.number,
  onSliderUpdate: PropTypes.any,
  isVideoCarousel: PropTypes.bool,
  hideArrowAtSlide: PropTypes.bool,
  IsCenterMode: PropTypes.bool,
}

export default CardSlider
