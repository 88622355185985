export const similar ={
    "classicsData" : {
         "cardsCollection": {
                "items": [
                  {
                    "title": null,
                    "text": "Select your sector\nand download your \n<span>FREE</span>recipe book",
                    "variant": "MULTI_SECTION_CAROUSEL_SUB_SLIDER_ONE",
                    "image": {
                      "url": "https://images.ctfassets.net/qc3lz96t0r4x/1WUNuixgxCbl5ICPAli6Dt/713d259acf147ded215a121f741fdefb/recipe-book.png",
                      "title": "Recipe Book",
                      "description": "",
                      "width": 900,
                      "height": 656
                    }
                  }
                ]
              },
              "link": {
                "label": "THE PHILLY FIVE",
                "title": null,
                "url": "/the-philly-five/education/",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "image": null
              },
              "isContentReversed": true
},
   "similarRecipe":{
   "cardsCollection": {
            "items": [
              {
                "title": null,
                "text": "Select your sector\nand download your \n<span>FREE</span>recipe book",
                "variant": "MULTI_SECTION_CAROUSEL_SUB_SLIDER_ONE",
                "image": {
                  "url": "https://images.ctfassets.net/qc3lz96t0r4x/1WUNuixgxCbl5ICPAli6Dt/713d259acf147ded215a121f741fdefb/recipe-book.png",
                  "title": "Recipe Book",
                  "description": "",
                  "width": 900,
                  "height": 656
                }
              }
            ]
          },
          "link": {
            "label": "THE PHILLY FIVE",
            "title": null,
            "url": "/the-philly-five/education/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "image": null
          },
          "isContentReversed": true
        },
        
}