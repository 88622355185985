import React from 'react'
import {screen, render} from '@testing-library/react'
import CarouselSlide from "../CarouselSlide/CarouselSlide";
import { slide } from '../../../mocks/CarouselSlide.mock';


describe('CarouselSlide ', () => { 

    render(<CarouselSlide {...slide}/>)
    let view = null
    beforeEach(() => {
        view = render(<CarouselSlide {...slide}/>)
    })  
    test('should render component', () => {
        render(<CarouselSlide {...slide}/>)
    })
    test('should render image', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })
    test('should render text', () => {
        expect(screen.getAllByText("Welcome to Phildelpia")).toBeInTheDocument
    })
})