import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import RecipeClassSlider from "./RecipeClassSlider/RecipeClassSlider"
import SearchRecipes from "./SearchRecipes/SearchRecipes"
import RecipeFilter from "./RecipeFilter/RecipeFilter"
import ImageWithText from "../ImageWithText/ImageWithText"
import RecipesList from "./RecipesList/RecipesList"
import { LEFT_QUOTE_CONTAINER } from "../ImageWithText/ImageWithText-tw-styles"
import { RECIPE_LISTING as style } from "./RecipeListing-tw-styles"
import Select from "../UI/Input/Select"
import { labels } from "../../constants/recipeListing.constants"
import ModalWrapper from "../Wrappers/ModalWrapper/ModalWrapper"
import variants from "../../constants/variants.constants"
import { useSelector, useDispatch } from "react-redux"
import { getSearchedRecipes } from "../../redux/actions/recipesActions"
import {
  updateSortOption,
  incrementPage,
  updateFilters,
  updateClafeFilter,
  removeClafeFilter,
  initAllRecipeControls,
} from "../../redux/actions/recipesListControlsAction"
import { getFilterClassesInArray, updatePageUrl } from "../../helpers/search"
import {
  eventSearchRecipeBtn,
  filterSortBy,
  seeMore,
} from "../../constants/gtm.constants"
import Button from "../UI/Button/Button"
import useDeepCompareEffect from "use-deep-compare-effect"
import { uniqBy, isEmpty } from "lodash"

const RecipeListing = props => {
  const { recipeClasses, recipeBook, formLink, filtersData } = props
  const { sortRecipes, filterRecipes } = labels
  const dispatch = useDispatch()
  const { recipes: recipeResponse, recipeListReducer } = useSelector(
    state => state
  )
  const { search, classFilters, pageNo, sortOption, clafeObj } =
    recipeListReducer
  const { isLoading, isError, value } = recipeResponse
  const { hits = [], nbPages, nbHits } = value
  const [recipesList, setRecipesList] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isClafeActive, toggleIsClafeActive] = useState(!isEmpty(clafeObj))
  const [isControlsSet, setIsControlsSet] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onpopstate = e => {
        setTimeout(() => {
          dispatch(initAllRecipeControls({ ...filtersData }))
        }, 100)
      }
    }
  })

  useEffect(() => {
    // init all list controls
    dispatch(initAllRecipeControls({ ...filtersData }))
    setIsControlsSet(true)
  }, [])

  // fetch recipes from algolia
  useEffect(() => {
    if (isControlsSet) {
      const classFiltersArray = getFilterClassesInArray(classFilters)
      setSelectedFilters([...classFiltersArray])
      dispatch(
        getSearchedRecipes({
          ...recipeListReducer,
          filtersArr: classFiltersArray,
        })
      )
    }
  }, [recipeListReducer, isControlsSet])

  // toggle show clafe classes
  useDeepCompareEffect(() => {
    toggleIsClafeActive(!isEmpty(clafeObj))
  }, [clafeObj])

  // update recipesList once algolia data is fetched
  useDeepCompareEffect(() => {
    let newList = pageNo ? [...recipesList] : []
    const uniqueArr = uniqBy([...newList, ...hits], e => e.RecipeID)
    setRecipesList([...uniqueArr])
  }, [hits])

  // close RecipeFilter pop up
  const closeModal = useCallback(() => {
    setOpenModal(false)
  }, [openModal])

  // increment page number in pagination
  const onSeeMoreBtnClick = () => {
    dispatch(incrementPage())
    updatePageUrl({ ...recipeListReducer, pageNo: pageNo + 1 })
  }

  // on classSlider card click
  const onRecipeCardClick = (e, recipeClass, dragging) => {
    if (dragging) {
      e.stopPropagation()
      return
    }
    toggleIsClafeActive(!isClafeActive)

    const clafeObj = {
      CategoryId: recipeClass.classId,
      CategoryName: recipeClass.text,
    }

    const filtersObj = {
      Sector: [{ ...clafeObj }],
      PhiladelphiaType: [],
      DishType: [],
    }

    dispatch(
      updateClafeFilter({
        classFilters: filtersObj,
        clafeObj: clafeObj,
      })
    )
    updatePageUrl({
      clafeObj,
      classFilters: filtersObj,
      pageNo: 0,
      search: "",
      sortOption: "",
    })
  }

  // select sort option
  const onDropdownSelect = e => {
    const value = e.target.value
    dispatch(updateSortOption(value))
    updatePageUrl({ ...recipeListReducer, sortOption: value })
  }

  // called when we remove a filter from SelectedFilters list
  const onDeleteClassFilter = categoryId => {
    const newClassFilters = {}
    Object.keys(classFilters).forEach(item => {
      newClassFilters[item] = []
      classFilters[item]?.forEach(category => {
        if (
          category?.CategoryId !== categoryId &&
          clafeObj?.categoryId !== categoryId
        ) {
          newClassFilters[item].push({ ...category })
        }
        if (clafeObj?.CategoryId === categoryId) {
          toggleIsClafeActive(!isClafeActive)
          dispatch(removeClafeFilter())
        }
      })
    })
    dispatch(updateFilters({ classFilters: newClassFilters, clafeObj }))
    updatePageUrl({ ...recipeListReducer, classFilters: newClassFilters })
  }

  {
    /* recipe list controls */
  }
  const buildListControls = () => {
    return (
      <div className={style.filterWrpr}>
        {nbHits ? (
          <div className={style.filterHedng}>
            Found <span className="bold">{nbHits}</span> Recipes
          </div>
        ) : (
          <div></div>
        )}
        <div className={style.filterDDown}>
          <Select
            options={sortRecipes?.sortDropdownOptions}
            label={sortRecipes?.sortLabel}
            onChange={onDropdownSelect}
            selectedValue={sortOption}
            variant={variants?.RECIPE_SORTING}
            gaEventClass={filterSortBy}
            className={style.dropDownBox}
          />
        </div>
        <div
          className={style.filterIcon}
          onClick={() => {
            setOpenModal(true)
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
          }}
        >
          <span
            className={"filter-icon event_filter_click"}
            data-action-detail={"filterRecipes"}
          ></span>
          <div className={style.filterLable}>{filterRecipes?.filterLabel}</div>
        </div>
      </div>
    )
  }

  {
    /* selected filters */
  }
  const listSelectedFilters = () => {
    return (
      <div className="bg-searchbg lg:h-[82px] px-10 py-20 lg:p-0 flex items-center">
        <div className="w-[1170px] mx-auto flex flex-wrap">
          <ul className="flex flex-wrap lg:gap-[7px] gap-[11px]">
            <span className="relative md:static w-full md:w-auto text-center">
              {labels?.selctedFiltersTitle}: &nbsp;
            </span>
            {selectedFilters?.map(category => {
              return (
                <li
                  key={category.CategoryId}
                  className={
                    "text-lightYellow bg-darkBlue rounded-[25px] lg:py-5 py-[2px] mr-5 px-10 lg:text-[11.5px] text-[15px]"
                  }
                >
                  <span className="mr-5">{category.CategoryName}</span>
                  <span
                    className="filter-close-icon cursor-pointer text-[9px] text-white"
                    onClick={() => {
                      onDeleteClassFilter(category?.CategoryId)
                    }}
                  ></span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className="recipelistwrap lg:mb-80 mb-40">
      {/* top class slider  */}
      {!isClafeActive && (
        <RecipeClassSlider
          {...recipeClasses}
          onRecipeCardClick={onRecipeCardClick}
        />
      )}
      {/* search component */}
      <SearchRecipes searchTerm={search} gaEventClass={eventSearchRecipeBtn} />
      {/* recipe book component */}
      <ImageWithText
        {...recipeBook}
        style={LEFT_QUOTE_CONTAINER}
        formLink={formLink}
        isClafeActive={isClafeActive}
        showBackground={true}
      />

      {/* list controls filter, sort */}
      {buildListControls()}

      {/* render selected filters */}
      {selectedFilters?.length ? listSelectedFilters() : null}

      {/* Filter Modal */}
      {openModal && (
        <ModalWrapper
          closeModal={closeModal}
          variant={variants?.RECIPE_LIST_FILTER}
          closeOnBodyclick={true}
        >
          <RecipeFilter
            recipeFilters={filtersData}
            closeModal={closeModal}
            isClafeActive={isClafeActive}
            toggleIsClafeActive={toggleIsClafeActive}
          />
        </ModalWrapper>
      )}

      {/* Loading Recipes List */}
      {isLoading && (
        <ModalWrapper>
          <div className="preloader-container ">
            <div className="sk-three-bounce flex flex-wrap block">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        </ModalWrapper>
      )}

      {/* Recipes List */}
      {nbHits ? (
        <RecipesList recipesList={recipesList} />
      ) : (
        <div className="text-center text-[15px] pt-[35px] pb-[60px]">
          {" "}
          {labels.noRecipes}{" "}
        </div>
      )}

      {/* See more button */}
      {pageNo < nbPages - 1 && (
        <Button
          text={seeMore}
          className="buttonEffect2 event_button_click"
          dataActionDetail={seeMore}
          onClick={onSeeMoreBtnClick}
        />
      )}
    </div>
  )
}

RecipeListing.propTypes = {
  recipeClasses: PropTypes.object,
  recipeBook: PropTypes.object,
  formLink: PropTypes.object,
  filtersData: PropTypes.object,
}

export default RecipeListing
