export const props = {
    "title": "Rendered",
    "text": "Discover how Philadelphia gets its fresh, creamy taste and smooth, consistent texture.",
    "variant": "ROW_REVERSE_CARD_LAYOUT",
    "isSlider": false,
    "sliderSettings": null,
    "link": {
        "label": "testlink"
    },
    "cardsCollection": {
      "items": [
        {
          "title": "We make it pretty good, pretty quick",
          "text": "\nWe keep our production facilities close to <br>\nour milk supplies, so we can transform fresh <br>\nmilk into Philadelphia in just 48 hours.",
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/7Cvr8UVLdwaD5fbIOCwrRu/bd891e978b28202664acec85aa9e699e/cow.png",
            "width": 250,
            "height": 162,
            "title": "cowimg",
            "description": "Cow icon"
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/64ilr1zF5fvrHmJk9ywBOk/1a0e2580b763a463de99308dc3f71884/line-1.png",
            "width": 265,
            "height": 83,
            "title": "line-1",
            "description": "path to next step"
          },
          "link": null
        },
        {
          "title": "A balancing act",
          "text": "We pasteurise sweet milk with added cream <br>\nto balance the fat content. Next, we add sour <br>\nmilk for acidity and then we extract the <br>\nwater.<br>",
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/mq5tlysp627pY2JfIaAda/a7282125c972b2f92cad5d6be69934a3/milk.png",
            "width": 189,
            "height": 172,
            "title": "milkimg",
            "description": "Milk churn icon"
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/3H9Jco6CQcKWwFiMpw6Psa/f033b0dde985ac69c85a379bca951769/line-2.png",
            "width": 265,
            "height": 79,
            "title": "line-2",
            "description": "path to next step"
          },
          "link": null
        },
        {
          "title": "Stirred to smooth perfection",
          "text": "Finally, we break down the fats and proteins <br>\nto create the smooth creamy texture that's <br>\nunique to Philadelphia.<br>",
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/20Oj5gjtfHu6CauYsifaGQ/a357153c6c66db5b68b5f0fd82f5bbc1/stirring.png",
            "width": 266,
            "height": 217,
            "title": "spinImg",
            "description": "Stirring icon"
          },
          "link": null
        }
      ]
    }
}

export const withSlider = {
    "title": "Rendered",
    "text": "Discover how Philadelphia gets its fresh, creamy taste and smooth, consistent texture.",
    "variant": "ROW_REVERSE_CARD_LAYOUT",
    "isSlider": true,
    "sliderSettings": {
        "slidesToShowMobile": 2,
        "slidesToScrollMobile": 2,
        "slidesToShowTab": 4,
        "slidesToScrollTab": 1
      },
    "link": {
        "label": "testlink"
    },
    "cardsCollection": {
      "items": [
        {
          "title": "We make it pretty good, pretty quick",
          "text": "\nWe keep our production facilities close to <br>\nour milk supplies, so we can transform fresh <br>\nmilk into Philadelphia in just 48 hours.",
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/7Cvr8UVLdwaD5fbIOCwrRu/bd891e978b28202664acec85aa9e699e/cow.png",
            "width": 250,
            "height": 162,
            "title": "cowimg",
            "description": "Cow icon"
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/64ilr1zF5fvrHmJk9ywBOk/1a0e2580b763a463de99308dc3f71884/line-1.png",
            "width": 265,
            "height": 83,
            "title": "line-1",
            "description": "path to next step"
          },
          "link": null
        },
        {
          "title": "A balancing act",
          "text": "We pasteurise sweet milk with added cream <br>\nto balance the fat content. Next, we add sour <br>\nmilk for acidity and then we extract the <br>\nwater.<br>",
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/mq5tlysp627pY2JfIaAda/a7282125c972b2f92cad5d6be69934a3/milk.png",
            "width": 189,
            "height": 172,
            "title": "milkimg",
            "description": "Milk churn icon"
          },
          "link": null
        },
        {
          "title": null,
          "text": null,
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/3H9Jco6CQcKWwFiMpw6Psa/f033b0dde985ac69c85a379bca951769/line-2.png",
            "width": 265,
            "height": 79,
            "title": "line-2",
            "description": "path to next step"
          },
          "link": null
        },
        {
          "title": "Stirred to smooth perfection",
          "text": "Finally, we break down the fats and proteins <br>\nto create the smooth creamy texture that's <br>\nunique to Philadelphia.<br>",
          "variant": "ROW_REVERSE_CARD",
          "gaEventClass": null,
          "gaEventLabel": null,
          "image": {
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/20Oj5gjtfHu6CauYsifaGQ/a357153c6c66db5b68b5f0fd82f5bbc1/stirring.png",
            "width": 266,
            "height": 217,
            "title": "spinImg",
            "description": "Stirring icon"
          },
          "link": null
        }
      ]
    }
  }