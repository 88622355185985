import React from 'react'
import {screen, render,within} from '@testing-library/react'
import { SimilarRecipes } from './SimilarRecipes'
 import { similar } from '../../mocks/SimilarRecipies.mock'


describe('SimilarRecipes ', () => { 
    render(<SimilarRecipes {...similar}/>)
    let view = null
    beforeEach(() => {
        view = render(<SimilarRecipes {...similar}/>)
    })  
   test('should render Intial Data', () => {
    render(<SimilarRecipes {...similar}/>)
    })
    test('should render ImageCards', () => {
        expect(screen.queryAllByRole(/presentation/i)).toBeInTheDocument
    })
   
    
})