import React from 'react'
import {screen, render} from '@testing-library/react'
import Input from '../Input/Input'
import {inputProps} from '../../../mocks/input.mock'

describe('Input', () => { 
    render(<Input {...inputProps}/>)
    test('should render component', () => {
        expect(screen.getByPlaceholderText("Input Field 1")) 
    })
 })