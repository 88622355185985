const query = (id, isPreview) => `
{
  articleVideo(id: "${id}", preview: ${isPreview}) {
    heading
    description
    video {
      title
      sitecoreVideo {
        url
        width
        height
        title
        description
      }
      youtubeVideoId
      posterImage {
        url
        width
        height
        title
        description
      }
      isModal
    }
    variant
    isContentReversed
  }
}
`
module.exports = { query }
