import React from 'react'
import {screen, render} from '@testing-library/react'
import Select from '../Input/Select'
import { SelectProps } from '../../../mocks/Select.mock'

describe('Select', () => {

    test('should render select field', () => {
        render(<Select {...SelectProps}/>)
        const SelectElement = document.querySelector('select')
        expect(SelectElement.id).toContain('select')
        expect(SelectElement.name).toContain('select')
    })

    test('Select Label', () => {
        render(<Select {...SelectProps}/>)
        expect(screen.getByText("Select Option"))
    })

    test('Option count', () => {
        render(<Select {...SelectProps}/>)
        expect(screen.getAllByRole('option').length).toBe(3)
    })

    test('Select Options', () => {
        render(<Select {...SelectProps}/>)
        expect(screen.getAllByRole('option', { value: '1' }))
        expect(screen.getAllByRole('option', { value: '2' }))
        expect(screen.getAllByRole('option', { value: '3' }))
    })

})