export const InlineViewProps = {
    poster: {
      url: 'https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg',
      title: 'tart',
      description: null,
      width: 900,
      height: 506,
    },
    image: {
       url: 'https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg',
      title: 'tart',
      description: null,
      width: 900,
      height: 506, 
    },
    title: "Video Player", 
    isIframe: true,
    isImageToggle: true,
    videoLink: "https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg",
    videoId: "K4PWIDfd8SY"
}