export const defaultStyle = {
  aVContainer: "w-full",
  aVHeading: "font-bold text-2xl pb-20",
  aVDescription: "pb-20 text-center",
  aVcontent: "aVcontent w-full lg:w-2/5 mx-auto pt-40 text-center ",
  aVideo: "aVideo full mx-auto relative",
}

export const FULL_WIDTH_VIDEO_WRPR = {
  videoframe: " px-10 lg:px-0",
  aVContainer:
    " aVContainer home-philly-video relative max-w-full xl:w-[955px] w-full lg:mx-auto px-15 flex flex-wrap justify-center text-center my-40 xl:my-50 max-w-full flex flex-col-reverse",
  contentReversed: "flex flex-col-reverse",
  aVcontentHeading:
    "aVcontentHeading text-darkBlue text-[40px] mt-50 mb-20 flex justify-center",
  aVDescription: "aVDescription rwast ",
  aVcontent: "videoText",
  description: "description text-40 mb-20 lg:mb-40 px-20 lg:px-auto [&>h3]:leading-[50px]  [&>h2]:leading-[50px]",
  videoframe: " px-10 lg:px-0",
  videoItem:
    "md:min-h-[398px] lg:min-h-[660px] min-h-[196px] lg:rounded-[30px] rounded-[20px] max-w-full",
  videoCntr: "videocontainer relative lg:min-h-[405px] max-w-full",
}
