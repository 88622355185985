import React from "react"
import PropTypes from "prop-types"
import ImageCard from "../../UI/ImageCard/ImageCard"
import Link from "../../UI/Link/Link"
import { buildCards } from "../../../helpers/BuildImageCardObject"

const RecipesList = props => {
  const { recipesList } = props
  const cardsProps = buildCards(recipesList)

  return (
    <div className='flex flex-wrap lg:py-15 lg:px-0 md:px-30 md:gap-10 py-15 justify-center items-center mx-auto lg:w-[1170px] mb-30 lg:mt-30 max-w-full customGrid'>
        {
            cardsProps?.map((card, index) => {
                return <Link key={index} {...card.link} className="recipeListImg">
                        <ImageCard {...card}/>
                    </Link>
            })
        }
    </div>
  )
}

RecipesList.propTypes = {
  recipesList: PropTypes.array,
}

export default RecipesList
