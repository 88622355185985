import React from 'react'
import {screen, render} from '@testing-library/react'
import Checkbox from '../Input/CheckBox'
import {checkBoxProps} from '../../../mocks/checkBox.mock'


describe('Checkbox', () => { 
    render(<Checkbox {...checkBoxProps}/>)
    test('should render component', () => {
        expect(screen.getByRole("checkbox",{id:"confirmationCheckbox"})) 
    })
 })