export const social = {
    variant:"Collection of social Links",
    showLabel:true,
    socialLinks: [
        {
            "label": "The Staff Canteen",
            "title": "The Staff Canteen",
            "url": "https://www.thestaffcanteen.com/#/",
            "target": "_blank",
            "imageType": null,
            "isAnchorTag": null,
            "scrollToElementId": null,
            "gaEventClass": "event_logo",
            "gaEventLabel": "The Staff Canteen",
            "image": {
              "title": "logo-staff-canteen",
              "description": "The Staff Canteen Logo",
              "url": "https://images.ctfassets.net/qc3lz96t0r4x/3Ck8PfIdM58dO0ZI6mkGEE/e81ab5bc6ed1264af82940b6f6e3b1b1/logo-staff-canteen.png",
              "width": 80,
              "height": 50
            }
          },
          {
            "label": null,
            "title": "InstagramIcon",
            "url": "https://www.instagram.com/philadelphiaprofessional_ukie/",
            "target": "_blank",
            "imageType": "svg",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "gaEventClass": "event_socialmedia_exit",
            "gaEventLabel": "Instagram",
            "image": {
              "title": "download-i",
              "description": "Instagram Icon",
              "url": "https://images.ctfassets.net/qc3lz96t0r4x/3p63nP2uveFmcrbt5Xphby/f8cfc354deeb435bc160c18092d3453e/clipart2664667.png",
              "width": 504,
              "height": 504
            }
          }
      ]
}