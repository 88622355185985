import React from 'react'
import {screen, render, fireEvent } from '@testing-library/react'
import SearchCardInput from '../SearchCardInput/index';
import { SearchCardInputProps } from '../../../mocks/SearchCardInput.mock'

describe('Search Card Input Component', () => {

    test('should render Button', () => {
        render(<SearchCardInput {...SearchCardInputProps}/>)
        expect(screen.getByText("Search")) 
    })

    test('should render Input', () => {
        render(<SearchCardInput {...SearchCardInputProps}/>)
        expect(screen.getByPlaceholderText("Search for recipes, ingredients, products")) 
    })

    test('get Input value', () => {
        render(<SearchCardInput {...SearchCardInputProps}/>)
        expect(screen.getByPlaceholderText("Search for recipes, ingredients, products").value).toBe("") 
    })

    test('change Input value', () => {
        render(<SearchCardInput {...SearchCardInputProps}/>)
        const inputfield = screen.getByPlaceholderText("Search for recipes, ingredients, products")
        fireEvent.change(inputfield, { target: { value: 'Test Value' } })
        expect(inputfield.value).toBe('Test Value')
    })
})