import React from 'react'
import {screen, render, within} from '@testing-library/react'
import Ingredients from './Ingredients'
import {IngredientProps} from '../../mocks/Ingredients.mock'

describe('Ingredients', () => { 
    render(<Ingredients {...IngredientProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<Ingredients {...IngredientProps}/>)
    })

    test('should render Ingredients Title', () => {
        expect(screen.getAllByText("Ingredients"))  
    })

    test('it should be a image', () => {
        const imageElement = document.querySelector('img')
        expect(imageElement.src).toContain('https://images.sweetauthoring.com/recipe/398288_2106.png')
    })

    test('list count', () => {
        const list = screen.getByRole("list")
        const { getAllByRole } = within(list)
        const items = getAllByRole("listitem")
        expect(items.length).toBe(22)
    })

})

