import React from 'react'
import {screen, render} from '@testing-library/react'
import ImageLayout from './ImageLayout'
import {imageLayoutProps} from '../../mocks/ImageLayout.mock'

describe('ImageLayout', () => { 
    render(<ImageLayout {...imageLayoutProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<ImageLayout {...imageLayoutProps}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText(/Chefs have spoken/i)).toBeInTheDocument;
    })
    test('should render tags', () => {      
        expect(screen.getAllByText(/They choose Philadelphia because it is a creamier, more consistent product with superior 1/i)).toBeInTheDocument;
    })  
 })