import React from "react"
import { render, screen} from "@testing-library/react"
import ImageSlider from "./ImageSlider";
import { SliderData } from "../../mocks/ImageSlider.mock";



describe("ImageSlider", () => {
    let view = null
    beforeEach(() => {
      view = render(<ImageSlider {...SliderData} />)
    })

    test('should render Intial Data', () => {
      render(<ImageSlider {...SliderData} />)
      })
      test('should render Images', () => {
          expect(screen.queryAllByRole(/presentation/i)).toBeInTheDocument
      })
    
    test("It should not render previous button on render", () => {
      const prevButton = view.container.querySelector("#btnPrev")
      expect(prevButton).toBe(null)
    })
   
    test("It should not render next button on render", () => {
      const nextButton = view.container.querySelector("#btnNext")
      expect( nextButton).toBe(null)
    })
  })
  