export const nutrientinfo = {
    text: "Enjoy creating",
    recipeTitle:"Chocolate, Philly and Avocado Pots",
    nutritionTitle: "Nutritional Information",
    nutrientData: {
      rowHeading: ["Typical values", "Per Serving"],
      rowData: [ 
     {"id":1,"typicalValues":"Energy","perServing":"932 kJ "},

    {"id":2,"typicalValues":"protein","perServing":"5.4g"},
    {"id":3,"typicalValues":"Carbohydrate","perServing":"4.3g"},
    {"id":4,"typicalValues":"of which Sugars","perServing":"4.3g"},
    {"id":5,"typicalValues":"of which Saturates","perServing":"14.0g"},
    {"id":6,"typicalValues":"Fibre","perServing":"0.2g"}
    
  
  ]
    },
    image: {
      url: "http://images.sweetauthoring.com/recipe/398283_2054.jpg?fm=webp&q=80",
      width: "457",
    },
    
    style: {
        moodTxt:
          "mood-text lg:text-[30px] text-[25px] mb-10 relative flex justify-center lg:justify-start",
        recipeText:
          "recipeText text-[30px] lg:w-[317px] text-center leading-[30px] px-20 lg:px-auto mb-[25px] lg:mb-[auto]",
        nutritionalTitle: "nutritionalTitle text-[30px] font-bold my-20 ",
        nutrientMainWrpr: "bg-darkBlue py-[70px] flex",
        nutrentWrpr:
          "nutrentWrpr  lg:w-[1170px] w-full mx-auto flex flex-wrap lg:px-[150px]",
        nutrientTblTxt: "nutrientTblTxt text-white lg:w-1/2 w-full px-10 lg:px-auto",
        nutrientTblImg: "nutrientTblImg lg:w-1/2 w-full hidden lg:block",
        recipeImage: "rounded-[20px]",
        nutrientTblImgMob:
          "nutrientTblImg lg:w-1/2 w-full lg:hidden  justify-center lg:flex",
         tableContainer: "NutritionTable",
        tableTitle: "font-normal text-3xl leading-4 mx-0 mb-30 mt-px p-0 text-center",
        
  }

}