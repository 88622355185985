const query = (id, isPreview)=> `
{
  recipeListingApi(id: "${id}", preview: ${isPreview})
        {
         heading
         description
         baseUrl
         variant
         methodName
         methodType
         sectorId
         categoryId
         slidersSettings{
          slidesToShowMobile
          slidesToShowTab
         }
       }
}`

module.exports = { query }
