import React from 'react'
import {screen, render} from '@testing-library/react'
import RecipeClassSlider from './RecipeClassSlider'
import {props} from '../../../mocks/RecipeClassSlider.mock'

describe('RecipeClassSlider', () => { 
    render(<RecipeClassSlider {...props}/>)
    let view = null
    beforeEach(() => {
        view = render(<RecipeClassSlider {...props}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText("Select the recipes for your sector, Chef")).toBeInTheDocument
    })
    test('should render slider', () => {
        expect(view.container.getElementsByClassName('slick-slider slick-initialized').length).toBe(1);    
    })
    test('should render image slides', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument    
    })
 })