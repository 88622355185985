import React, { useState } from "react"
import * as styles from "./FullScreenMenu-tw-styles"
import ModalWrapper from "../Wrappers/ModalWrapper/ModalWrapper"
import ImageCard from "../UI/ImageCard/ImageCard"
import Link from "../UI/Link/Link"
import { get } from "../../helpers/utils"
import Button from "../UI/Button/Button"
import PropTypes from "prop-types"

function FullScreenMenu(props) {
  const { variant, navigationCollection, cardsCollection } = props
  const style = styles[variant] || styles.defaultStyle
  const cards = get(cardsCollection, "items", [])
  const navigation = get(navigationCollection, "items", [])
  const [isModalOpen, setModalState] = useState(false)
  const closeModal = () => {
    setModalState(false)
  }

  const handleclick = e => {
    setModalState(true)
  }

  return (
    <div>
      <Button onClick={handleclick} className={style?.btnstyle}>
        <div className={style?.icon}></div>
        <div className={style?.icon}></div>
      </Button>

      {isModalOpen && (
        <ModalWrapper
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          variant={variant}
        >
          {navigation && (
            <ul>
              {navigation.map((nav, index) => (
                <li
                  key={index}
                  className="nav-item uppercase w-full  py-10 border-b-1 pl-15 text-center text-22 font-bold"
                >
                  {nav.link && (
                    <Link {...nav.link} eventClass="event_menu_click">
                      {nav.label}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}

          {cards && (
            <div className={style?.imgWrapper}>
              {cards.map((card, index) => (
                <ImageCard {...card} key={index} variant={variant} />
              ))}
            </div>
          )}
        </ModalWrapper>
      )}
    </div>
  )
}

FullScreenMenu.propTypes = {
  navigationCollection: PropTypes.object,
  cardsCollection: PropTypes.object,
  variant: PropTypes.string,
}

export default FullScreenMenu
