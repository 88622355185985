import React from "react"
import { render, screen, fireEvent } from "@testing-library/react"
import ImageCarousel from "../ImageCarousel"
import { props } from "./ImageCarousel.mocks"

describe("ImageCarousel", () => {
  let view = null
  beforeEach(() => {
    view = render(<ImageCarousel {...props} />)
  })

  test("It should render ImageCarousel correctly", () => {
    expect(screen.getByText(/Welcome Chef/)).toBeInTheDocument
  })
  test("It should not render previous button on render", () => {
    const prevButton = view.container.querySelector("#btnPrev")
    expect(prevButton).toBe(null)
  })

  test("It should go to next slide on next button click", () => {
    const nextButton = view.container.querySelector("#btnNext")
    fireEvent.click(nextButton)
    expect(screen.getByText(/Irreplaceable Ingredient/)).toBeInTheDocument
  })
})
