import * as actionTypes from '../../constants/redux.actionTypes'
const { getSearchResults } = require('../../adapters/algolia-adapter')

// action creator to get recipes from algolia
export const getSearchedRecipes = (recipesListData) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TOGGLE_LOAD
        })

       getSearchResults(recipesListData).then(recipesRes => {
            dispatch({
                        type: actionTypes.GET_RECIPES,
                        recipesRes
                    })
        })
        .catch(err => {
            dispatch({
                type: actionTypes.RECIPE_FETCH_ERROR,
            })
        })
    }
}