import React from 'react'
import {screen, render} from '@testing-library/react'
import Header from './Header'
import {props} from '../../../mocks/Header.mock'
import createStore from "../../../redux/store"
import {Provider} from 'react-redux' 

const store = createStore()
describe('Header', () => { 
    render(<Provider store={store}><Header {...props}/></Provider>)
    let view = null
    beforeEach(() => {
        view = render(<Provider store={store}><Header {...props}/></Provider>)
    })  
    test('should render header logo', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })  
    test('should render header links', () => {
        expect(screen.getByText(/THE PHILLY DIFFERENCE/i).href).toBe("http://localhost/phillydifference/")
        expect(screen.getByText(/THE RANGE/i).href).toBe("http://localhost/range/")
        expect(screen.getByText(/THE RECIPES/i).href).toBe("http://localhost/recipes/")
        expect(screen.getByText(/THE PHILLY FIVE/i).href).toBe("http://localhost/the-philly-five/education/")
    })
 })