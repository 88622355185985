import React from 'react'
import {screen, render} from '@testing-library/react'
import Radio from '../Input/Radio'
import { radioProps } from '../../../mocks/Radio.mock'

describe('Radio', () => { 
    render(<Radio {...radioProps}/>)
    test('should render component', () => {
        expect(screen.getByRole("radio",{id:"radio"})) 
    })
 })