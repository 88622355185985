import React from 'react'
import {screen, render} from '@testing-library/react'
import ImageCard from '../ImageCard/ImageCard'
import { ImageCardProps } from '../../../mocks/ImageCard.mock'

describe('ImageCard', () => {

    test('should render Image and src to be true', () => {
        render(<ImageCard {...ImageCardProps}/>)
        const imageElement = document.querySelector('img')
        expect(imageElement.src).toContain('https://images.ctfassets.net/qc3lz96t0r4x/1RQvvw3AthmlCK90TRHejr/66253cd6e1e2b08ab153ffa275a12353/MicrosoftTeams-image__1_.png')
    })

    test('should render Text', () => {
        render(<ImageCard {...ImageCardProps}/>)
        screen.getByText(`ImageCard`)
    })

    test('should render Title', () => {
        render(<ImageCard {...ImageCardProps}/>)
        screen.getByText(`Philadelphia Original 10kg`)
    })

    test('should render Link', () => {
        render(<ImageCard {...ImageCardProps}/>)
        expect(screen.getByText("Discover more").title).toBe("")
    })

})