export const COOKIES_INFO = {
    richTextWrapper:
      "richTextWrapper text-left px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] lg:mx-auto my-0",
    title: "text-center text-25 leading-25 py-25 tracking-[1px]",
    code: "",
    paragraph: "paragraph",
    list: "list pb-[1.1em] list-none tracking-[0]",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "border-separate border-spacing-0 curved-table lg:mb-30 mb-10",
    tRow: "rounded-[0px]",
    tHeader:
      "bg-greyLightSmoke  text-14 first:rounded-tl-[5px] last:rounded-tr-[5px]",
    tData: "",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
  }