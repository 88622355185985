export const NewsLetterData  = {
    "jsonEditor": {
        "text": "Not Registered?",
        "submit": {
            "name": "submit",
            "error": "Some information seems incomplete or incorrect. To make corrections please to continue.",
            "label": "Submit"
        },
        "variant": "formInputStyle",
        "checkbox": {
            "aggrementCheckbox": {
                "icon": "",
                "name": "aggrementCheckbox",
                "error": "",
                "label": "<p>Tick this box to grant Cadbury with permission to use your personal information to help us ensure our future communications with you, either through our partners or across third party platforms, are more relevant and tailored to you and the preferences you have shared. Cadbury is an affiliate brand of Mondelez.</p>  ",
                "value": false,
                "isValid": true,
                "fieldType": "checkbox",
                "isRequired": false,
                "submittion": true,
                "elementType": "input",
                "elementConfig": {
                    "type": "checkbox",
                    "placeholder": ""
                }
            }
        },
        "fieldList": {
            "firstName": {
                "name": "firstName",
                "placeholder": "First Name",
                "error": "",
                "label": "First Name",
                "rules": [
                    {
                        "type": "REQUIRED",
                        "errorMessage": "First name Required"
                    },
                    {
                        "type": "IS_HTML",
                        "errorMessage": "Please enter valid First name",
                        "successMessage": "The Field is validated"
                    }
                ],
                "value": "",
                "isValid": true,
                "fieldType": "input",
                "maxLength": 256,
                "isRequired": true,
                "submittion": true,
                "successMsg": "",
                "elementType": "input",
                "elementConfig": {
                    "type": "text",
                    "placeholder": "First Name"
                }
            },
            "email": {
                "icon": "",
                "name": "email",
                "placeholder": "Email",
                "error": "",
                "label": "Email",
                "rules": [
                    {
                        "type": "REQUIRED",
                        "errorMessage": "Email Required"
                    },
                    {
                        "type": "EMAIL",
                        "errorMessage": "Please enter valid email",
                        "successMessage": "The Field is validated"
                    }
                ],
                "value": "",
                "isValid": true,
                "fieldType": "input",
                "isRequired": true,
                "submittion": true,
                "successMsg": "",
                "elementType": "input",
                "elementConfig": {
                    "type": "text",
                    "placeholder": "Email"
                }
            },
            "reEnterEmail": {
                "icon": "",
                "name": "confirmEmail",
                "error": "",
                "label": "Re-enter Email",
                "placeholder": "Re-enter Email",
                "rules": [
                    {
                        "type": "REQUIRED",
                        "errorMessage": "Re-Enter Email Required"
                    },
                    {
                        "type": "EMAIL",
                        "errorMessage": "Please enter valid email",
                        "successMessage": "The Field is validated"
                    },
                    {
                        "type": "EQUAL_TO",
                        "errorMessage": "Email does not Matched",
                        "successMessage": "The Field is validated"
                    }
                ],
                "value": "",
                "isValid": true,
                "fieldType": "input",
                "isRequired": true,
                "submittion": true,
                "successMsg": "",
                "elementType": "input",
                "elementConfig": {
                    "type": "text",
                    "placeholder": "Re-Enter Email"
                }
            }
        },
        "dataSiteKey": "6LfuOq8mAAAAACJYdmQm4aOU7XfC3QL3eG7Ec-IM",
        "description": "<p>We value your trust when sharing your personal data with us. We always treat your data in a fair and respectful manner limited to the purpose above mentioned. If you would like to know more about how we handle your data, please read our <a href='https://eu.mondelezinternational.com/privacy-notice?sc_lang=en-gb&siteId=Zv0BAiPw9HLxCuKyFDGRiyYZfufseHmj'>Privacy Notice</a>.</p>",
        "reCaptchaError": "Recaptcha validation failed"
    }
}