import React from "react"
import PropTypes from "prop-types"

const SortMenu = props => {
  const { setActiveOption, sortOptions } = props

  return (
    <select onChange={e => setActiveOption(e.target.value)}>
      {Object.keys(sortOptions).map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  )
}

SortMenu.propTypes = {
  setActiveOption: PropTypes.func,
  sortOptions: PropTypes.object,
}

export default SortMenu
