import React from "react"
import { render } from "@testing-library/react"
import VideoPlayer from "./VideoPlayer"
import { VideoPlayerProps } from "../../../mocks/VideoPlayer.mock"

describe("VideoPlayer", () => {
  render(<VideoPlayer {...VideoPlayerProps} />)
  let view = null
  beforeEach(() => {
    view = render(<VideoPlayer {...VideoPlayerProps} />)
  })

  test("should render Poster Image", () => {
    const imageElement = document.querySelector("img")
    expect(imageElement.src).toContain(
      "https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg"
    )
  })

  test("should render Iframe", () => {
    const iframeElement = document.querySelector("iframe")
    expect(iframeElement.id).toContain("K4PWIDfd8SY")
    expect(iframeElement.title).toContain("Video Player")
    expect(iframeElement.src).toContain(
      "https://www.youtube-nocookie.com/embed/K4PWIDfd8SY?html5=1&showinfo=0&rel=0&modestbranding=0&playsinline=1&enablejsapi=1&origin=http://localhost"
    )
  })
})
