import React, { useState } from "react"
import PropTypes from "prop-types"
import { labels } from "../../../constants/label.constants"

const SearchCardInput = props => {
  const { setSearchTerm } = props
  const [searchValue, setsearchValue] = useState("")

  const handleKeyPress = event => {
    if (event?.key === "Enter") {
      const content = event?.target?.value.toString()
      setsearchValue(content)
      setSearchTerm(content)
    }
  }

  return (
    <div>
      <input
        role="searchbox"
        id="search-field-dt"
        className="search-field js-search-field"
        aria-label={"Search for recipes, ingredients, products"}
        placeholder={"Search for recipes, ingredients, products"}
        onKeyPress={handleKeyPress}
        onChange={e => setsearchValue(e.target.value)}
      />
      <button
        type="button"
        tabIndex={0}
        className="search-btn"
        onClick={() => setSearchTerm(searchValue)}
      >
        {labels.search}
      </button>
    </div>
  )
}

SearchCardInput.propTypes = {
  setSearchTerm: PropTypes.func,
}

export default SearchCardInput
