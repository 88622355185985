import React from 'react'
import {screen, render} from '@testing-library/react'
import Typography from '../Typography/Typography'
import { TypographyProps } from '../../../mocks/Typography.mock'

describe('Typography', () => { 
    render(<Typography {...TypographyProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<Typography {...TypographyProps}/>)
    })

    test('should render component', () => {
        expect(screen.getAllByText("This is Typography Component"))   
    })
})