export const checkBoxProps={
    id: "confirmationCheckbox",
    isValid: true,
    label: "I confirm that I am over 18 years old*",
    name: "confirmationCheckbox",
    "style": {
      inputContainer: "lg:mb-3 mb-27 xl:w-96 ",
      labelStyle: "inline-block p-5 text-lg leading-[22px] text-darkBlue  ",
      text: "form-control block w-full p-14 text-base font-normal text-gray-700  bg-white bg-clip-padding  border border-solid  rounded-[25px] border-gray border-solid border-2 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none formInput",
      select:
        "form-select bg-no-repeat bg-right appearance-none block w-full p-14 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat  border border-solid border-gray rounded-[25px] transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none bg-[94% center]  bg-[0% 96%]",
      chkWrpr: "flex my-20 odd:pb-20",
      checkBox:
        "form-check-input h-33 w-33 border  rounded-md bg-white checked:bg-darkBlue checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer formInput",
      checkBoxText: "inline-block p-5 text-sm leading-5 text-darkBlue lg:w-[400px]",
      error: "border-2 border-red-600",
    },
}