export const props = {
    "heading": "Select the recipes for your sector, Chef",
    "recipeClassesCollection": {
      "items": [
        {
          "text": "Education",
          "classId": "21138",
          "recipeImage": {
            "title": "education",
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/LnFUZ2tAJas4iQMO2Gky5/dfb0e44fc4f8b3bcf48d7fe2e217863f/education.jpg",
            "description": null,
            "width": 400,
            "height": 400
          }
        },
        {
          "text": "Hotels",
          "classId": "21137",
          "recipeImage": {
            "title": "hotels",
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/2Zo4VqtoJuFH2Jymg9x65i/7649f7549527211d8dbf73ab6e6e2421/hotels.jpg",
            "description": null,
            "width": 400,
            "height": 400
          }
        },
        {
          "text": "Food-to-Go",
          "classId": "21139",
          "recipeImage": {
            "title": "food-to-go",
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/6B0Vh6NxRFMbish5Wlug3a/33b33350de2e1f62bb218443e11fa7b1/food-to-go.jpg",
            "description": null,
            "width": 400,
            "height": 400
          }
        },
        {
          "text": "Restaurants & Pubs",
          "classId": "21140",
          "recipeImage": {
            "title": "restaurants-and-pubs",
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/2ityseFc2jsBxCjhht65Xp/076a6395f08a21e0fc447df241cf367f/restaurants-and-pubs.jpg",
            "description": null,
            "width": 400,
            "height": 400
          }
        },
        {
          "text": "Healthcare",
          "classId": "21141",
          "recipeImage": {
            "title": "healthcare",
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/2I3Ye5ZVLFbzoFCzBm5OC9/38679d3d679e67ba555095e87d7a7f23/healthcare.jpg",
            "description": null,
            "width": 400,
            "height": 400
          }
        }
      ]
    }
  }