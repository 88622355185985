const query = (id, isPreview ) => `{
    twoImageWithCenterText(id: "${id}", preview: ${isPreview}) {
        leftImage {
            url
        }
        rightImage {
            url
        }
        title
        description
        buttonLink {
            url
            target
            label
            gaEventClass
            gaEventLabel
        }
    }
 }`

 module.exports = {query}
 