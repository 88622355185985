import React from 'react'
import {screen, render} from '@testing-library/react'
import Link from '../Link/Link'
import {linkButton} from '../../../mocks/link.mocks'

describe('Link', () => { 
    render(<Link {...linkButton}/>)
    test('should render component', () => {
        expect(screen.getByText("Discover more")) 
    })
 })