export const IconButtonProps = {
  "keyValue": "recepieDetail",
  "variant": "socialIconVar",
  "links": {
    "__typename": "ContentfulIconButton",
    "title": "Spread the word, chef! ",
    "image": {
      "title": "round-arrow-blue",
      "description": "",
      "url": "https://images.ctfassets.net/qc3lz96t0r4x/2IagnMhmxNVx6IRh4CUtiL/3fdd980773c172824dea6b604e771b61/round-arrow-blue.png",
      "width": 104,
      "height": 48
    },
    "socialLinks": [
      {
        "label": "Instagram",
        "title": "Instagram",
        "url": "https://www.instagram.com/philadelphiaprofessional_ukie/",
        "target": "_blank",
        "isAnchorTag": null,
        "gaEventClass": "event_social_share",
        "gaEventLabel": "Instagram",
        "image": {
          "title": "instagram",
          "description": "",
          "url": "https://images.ctfassets.net/qc3lz96t0r4x/4oB7A7ohSamRUc0O148Uxo/1178e751bc295b03e184851b24a00cae/instagram.svg",
          "width": 300,
          "height": 300
        }
      },
      {
        "label": "Whatsapp",
        "title": "Whatsapp",
        "url": "https://web.whatsapp.com/",
        "target": "_blank",
        "isAnchorTag": null,
        "gaEventClass": "event_social_share",
        "gaEventLabel": "Whatsapp",
        "image": {
          "title": "WhatsappImage",
          "description": "",
          "url": "https://images.ctfassets.net/qc3lz96t0r4x/7HGvvNwoGAIXtpuUcplf2E/12c1e0a53c18175272faef051559efc3/whatsapp.svg",
          "width": 300,
          "height": 300
        }
      },
      {
        "label": "Mail",
        "title": "Mail",
        "url": null,
        "target": "_self",
        "isAnchorTag": null,
        "gaEventClass": "event_social_share",
        "gaEventLabel": "Mail",
        "image": {
          "title": "mail",
          "description": "",
          "url": "https://images.ctfassets.net/qc3lz96t0r4x/3qN0dFXzMzjC36T7OTIWhk/49d595bc61bed2eccafc666ddff4dbfe/mail.svg",
          "width": 300,
          "height": 300
        }
      },
      {
        "label": "Print",
        "title": "Print",
        "url": null,
        "target": "_self",
        "isAnchorTag": null,
        "gaEventClass": "event_social_share",
        "gaEventLabel": "Print",
        "image": {
          "title": "print-gray",
          "description": "",
          "url": "https://images.ctfassets.net/qc3lz96t0r4x/3FCvL2cH5NMeoaL03HkI2I/083810407cd78a1771f9e4459f1a7b0f/print-gray.svg",
          "width": 300,
          "height": 300
        }
      },
      {
        "label": "Bookmark",
        "title": "Bookmark",
        "url": null,
        "target": "_self",
        "isAnchorTag": null,
        "gaEventClass": "event_social_share",
        "gaEventLabel": "Bookmark",
        "image": {
          "title": "bookmark",
          "description": "",
          "url": "https://images.ctfassets.net/qc3lz96t0r4x/5dWASZNTiAEOaz8B39aUcl/2d1bd79fe8435aef69f02c600c4bc98f/bookmark.svg",
          "width": 300,
          "height": 300
        }
      }
    ]
  }
}