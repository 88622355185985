export const SEARCH_RECIPES = {
    container: "bg-searchbg lg:h-[110px] h-[158px] flex items-center",
    button:"bg-white rounded-[25px] text-darkBlue h-50 text-18 font-ProxRegular px-30 lg:ml-20  mt-20 lg:mt-0 w-full lg:w-auto",
    serachWrpr:'serachWrpr lg:w-[1170px] lg:mx-auto mx-10 flex flex-wrap w-full justify-center',
    searchboxStyles : {
        formContainer: "formContainer bg-white rounded-[25px] lg:w-400 w-full flex flex-wrap h-50",
        form:"form  flex items-center justify-center w-4/5",
        image:"image w-[25px] ml-10 text-[25px] mt-5",
        input:"input bg-transparent w-full ml-10 flex h-full outline-none placeholder-placeholderColor",
        searchPlaceHolder:'placeholder-darkBlue'
      }
}