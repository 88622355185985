import React from 'react'
import {screen, render} from '@testing-library/react'
import ImageHeroBanner from './ImageHeroBanner'
import {bannerProps} from '../../mocks/ImageHeroBanner.mock'

describe('ImageHeroBanner', () => { 
    render(<ImageHeroBanner {...bannerProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<ImageHeroBanner {...bannerProps}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText("Philadelphia Original Portions (24 x 16.7g)")).toBeInTheDocument
    })
    test('should render Banner Image', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })

    test('should render Back Link', () => {
        expect(screen.getAllByText("Back")).toBeInTheDocument
    })
 })