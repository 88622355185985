export const SliderData ={
    slide:[{
        "image": {
            "title": "Recipe Book",
            "description": "",
            "contentType": "image/png",
            "fileName": "recipe-book.png",
            "size": 210956,
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/2TF3mHdmGyQRW3ixhfdrAB/f1246a043b0d77bdb9a648197ce0d10c/recipe-book.png",
            "width": 600,
            "height": 437
          },
          "width": 600,
          "height": 437,
          "alt": "card one"
},
{
    "image": {
        "title": "Recipe Book two",
        "description": "",
        "contentType": "image/png",
        "fileName": "recipe-book.png",
        "size": 210956,
        "url": "https://images.ctfassets.net/qc3lz96t0r4x/2TF3mHdmGyQRW3ixhfdrAB/f1246a043b0d77bdb9a648197ce0d10c/recipe-book.png",
        "width": 600,
        "height": 437
      },
      "width": 600,
      "height": 437,
      "alt": "card two"
}]
}