export const defaultStyle = {
  contentWrapper: "w-[340px] mx-auto",
  heading: "text-[#da2b1f] mb-[20px] text-4xl font-bold mb-20 ",
  resetPassword: "text-base block mt-2 text-right capitalize text-gray-700",
  signUpBtn: "signUpBtn",
}

export const banner = {
  wrapper: "bg-[#ffe179] grid grid-cols-2",
  contentWrapper: "w-[400px] mx-auto  ",
  heading: "text-[#2b2b2b] mb-[40px] mt-[50px] text-4xl font-bold mb-20 ",
  description: "text-[#2b2b2b]  mb-[20px] ",
  resetPassword: "text-base block mt-2 text-right capitalize text-gray-700",
  signUpBtn: "signUpBtn2",
}
