export const props =  {
    "variant": "FOUR_SLIDES_CAROUSEL",
    "slidesCollection": {
      "items": [
        {
          "title": "Welcome Chef",
          "text": "You may not know it, but there's so much more to Philadelphia than cheesecakes and bagels.\n\n<span>It's Time to Get Inspired</span>",
          "variant": "MULTI_SECTION_CAROUSEL_SLIDE_ONE",
          "cardsCollection": {
            "items": [
              {
                "title": null,
                "text": "Select your sector\nand download your \n<span>FREE</span>recipe book",
                "variant": "MULTI_SECTION_CAROUSEL_SUB_SLIDER_ONE",
                "image": {
                  "url": "https://images.ctfassets.net/qc3lz96t0r4x/1WUNuixgxCbl5ICPAli6Dt/713d259acf147ded215a121f741fdefb/recipe-book.png",
                  "title": "Recipe Book",
                  "description": "",
                  "width": 900,
                  "height": 656
                }
              }
            ]
          },
          "link": {
            "label": "THE PHILLY FIVE",
            "title": null,
            "url": "/the-philly-five/education/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "image": null
          },
          "isContentReversed": true
        },
        {
          "title": "Irreplaceable Ingredient",
          "text": "The combination of its rich, creamy taste and silky-smooth consistency, ensures Philadelphia always works hard in your dishes.",
          "variant": "MULTI_SECTION_CAROUSEL_SLIDE_TWO",
          "cardsCollection": {
            "items": [
              {
                "title": null,
                "text": null,
                "variant": "MULTI_SECTION_CAROUSEL_SUB_SLIDER_TWO",
                "image": {
                  "url": "https://images.ctfassets.net/qc3lz96t0r4x/457dkdkBIfLbIpIE4SHEmt/2699e5bb75ebed2089c2e821b58777d8/philadelphia-product.png",
                  "title": "philadelphia-product",
                  "description": "",
                  "width": 1042,
                  "height": 908
                }
              }
            ]
          },
          "link": null,
          "isContentReversed": true
        }
      ]
    }
  }