import React from "react"
import PropTypes from "prop-types"
import * as styles from "./TwoImageWithCenterText-tw-styles"
import Image from "../UI/Image/Image"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"

const TwoImageWithCenterText = props => {

    const {
        title,
        description,
        leftImage,
        rightImage,
        buttonLink,
      } = props
    
    const style = styles.defaultStyle
    return(
        <section className={style.background}>
        <div className={style.container}>
            <div className="w-full lg:w-[30%] xl:w-[350px]">
                <Image
                contentfulImage={leftImage}
                className={""}
                />
            </div>
            <div className="w-full lg:w-[40%] xl:w-[460px] text-white pt-20 pb-40 lg:py-0">
                {title && (
                    <Typography className={style?.heading2} content={title} />
                )}
                {description && (
                    <Typography className={style?.description} content={description} />
                )}
                {buttonLink && (
                    <Link url={buttonLink.url} title={buttonLink.label} className={style?.linkWrpr}>{buttonLink.label}</Link>
                )}
            </div>
            <div className="w-full lg:w-[30%] xl:w-[350px]">
                <Image
                contentfulImage={rightImage}
                className={""}
                />
            </div>
        </div>
        </section>
    ) 
}

TwoImageWithCenterText.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    buttonLink: PropTypes.object,
  }

export default TwoImageWithCenterText