export const ImageCardProps ={
    text: 'ImageCard',
    title: 'Philadelphia Original 10kg',
    image: {
        title: 'Philadelphia Original 10kg',
        description: '',
        contentType: 'image/png',
        fileName: 'MicrosoftTeams-image (1).png',
        size: 113589,
        url: 'https://images.ctfassets.net/qc3lz96t0r4x/1RQvvw3AthmlCK90TRHejr/66253cd6e1e2b08ab153ffa275a12353/MicrosoftTeams-image__1_.png',
        width: 600,
        height: 600,
    },
    link: {
        name: 'Discover more - 10kg',
        label: 'Discover more',
        title: 'philadelphia-original-10kg',
        target: '_self',
        isAnchorTag: null,
        scrollToElementId: null,
        imageType: null,
    }
}