const query = (id, isPreview) => `
{
    videoSlider(id: "${id}", preview: ${isPreview}) {
        isSlider
        heading
        elementId
        description
        backgroundImage {
          url
          title
          description
          width
          height
        }
        leftBackgroundImage {
          url
          title
          description
          width
          height
        }
        rightBackgroundImage {
          url
          title
          description
          width
          height
        }
        mobileLeftBackgroundImage
        {
           url
          title
          description
          width
          height
        }
        mobileRightBackgroundImage{
             url
          title
          description
          width
          height
        }
        videosCollection {
          items {
            ... on Video {
            isModal
            isOnlyImage
            posterImage {
              url
              title
              description
              width
              height
            }
            youtubeVideoId
            sitecoreVideo {
              url
              width
              height
              title
              description
            }
          }
          ... on ImageWithText {
            isOnlyImage
            heading
            description
            isReversed
            variant
            image {
              title
              description
              url
              width
              height
            }
          }
          }
        }
        sliderRichText {
          name
          variant
          title
          richText {
            json
            links {
              entries {
                inline {
                  sys {
                    id
                  }
                  __typename
                  ... on SuperScript {
                    superScript
                  }
                  ... on SubScript {
                    subScript
                  }
                }
              }
            }
          }
          link {
            name
            label
            title
            url
            target
            isAnchorTag
            scrollToElementId
            gaEventClass
            gaEventLabel
          }
        }
    }
}
`
module.exports = { query }
