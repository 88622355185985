export const props = 
      {
        "note": "Philadelphia is available nationwide via all good wholesalers",
        "socialLinksCollection": {
          "items": [
            {
              "label": "The Staff Canteen",
              "title": "The Staff Canteen",
              "url": "https://www.thestaffcanteen.com/#/",
              "target": "_blank",
              "imageType": null,
              "isAnchorTag": null,
              "scrollToElementId": null,
              "gaEventClass": "event_logo",
              "gaEventLabel": "The Staff Canteen",
              "image": {
                "title": "logo-staff-canteen",
                "description": "The Staff Canteen Logo",
                "url": "https://images.ctfassets.net/qc3lz96t0r4x/3Ck8PfIdM58dO0ZI6mkGEE/e81ab5bc6ed1264af82940b6f6e3b1b1/logo-staff-canteen.png",
                "width": 80,
                "height": 50
              }
            },
            {
              "label": null,
              "title": "InstagramIcon",
              "url": "https://www.instagram.com/philadelphiaprofessional_ukie/",
              "target": "_blank",
              "imageType": "svg",
              "isAnchorTag": null,
              "scrollToElementId": null,
              "gaEventClass": "event_socialmedia_exit",
              "gaEventLabel": "Instagram",
              "image": {
                "title": "download-i",
                "description": "Instagram Icon",
                "url": "https://images.ctfassets.net/qc3lz96t0r4x/3p63nP2uveFmcrbt5Xphby/f8cfc354deeb435bc160c18092d3453e/clipart2664667.png",
                "width": 504,
                "height": 504
              }
            }
          ]
        },
        "mainLinksCollection": {
          "items": [
            {
              "label": null,
              "linksCollection": {
                "items": [
                  {
                    "label": "CONTACT",
                    "title": null,
                    "url": "https://contactus.mdlzapps.com/form?siteId=mZxEmnBvrkt3Ey1XJ8o8Vpuy6OncMIjle70mYoYdMP4%3d",
                    "target": "_blank",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "CONTACT",
                    "image": null
                  },
                  {
                    "label": "SITEMAP",
                    "title": null,
                    "url": "/site-map/",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "SITEMAP",
                    "image": null
                  },
                  {
                    "label": "COMPANY DETAILS",
                    "title": null,
                    "url": "/corporate-information/company-details/",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "COMPANY DETAILS",
                    "image": null
                  },
                  {
                    "label": "COOKIES INFO",
                    "title": null,
                    "url": "/corporate-information/cookies-info/",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "COOKIES INFO",
                    "image": null
                  },
                  {
                    "label": "PRIVACY NOTICE",
                    "title": null,
                    "url": "/corporate-information/privacy-policy/",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "PRIVACY NOTICE",
                    "image": null
                  },
                  {
                    "label": "TERMS OF USE",
                    "title": null,
                    "url": "/corporate-information/terms-of-use/",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "TERMS OF USE",
                    "image": null
                  },
                  {
                    "label": "CAREERS",
                    "title": null,
                    "url": "https://www.mondelezinternational.com/careers/?utm_source=philadelphiaprofessional.co.uk&utm_medium=int&utm_campaign=footer-link",
                    "target": "_blank",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "CAREERS",
                    "image": null
                  },
                  {
                    "label": "CORPORATE REPORTING",
                    "title": null,
                    "url": "/corporate-information/corporate-reporting/",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": "event_external_link",
                    "gaEventLabel": "CORPORATE REPORTING",
                    "image": null
                  }
                ]
              }
            }
          ]
        },
        "brandLinksCollection": {
          "items": [
            {
              "url": "https://www.mondelezinternational.com/europe",
              "image": {
                "url": "https://images.ctfassets.net/qc3lz96t0r4x/5hDM3gQxPMuVgGcTnWZRfb/0e08674e2fe34f0d59a7b18a4dac4728/mdlz-logo-footer.svg",
                "title": "Logo",
                "description": "Mondelez Logo",
                "width": 170,
                "height": 42
              }
            }
          ]
        }
      }
