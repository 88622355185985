const mondelezAPI = {
  productURL: "https://dev-api-abstract.mdlzapps.cloud/product",
  recipeURL: "https://dev-api-abstract.mdlzapps.cloud/recipe",
  recipesList: "",
  sectorID: "21136",
  siteId: "philadelphia_professional",
  locale: "uken",
  languageId: 1,
  websiteId: 130,
  sortFrom: 1,
  sortTo: 1,
  pageNumber: 1,
  pageSize: 100,
  timeSpan: [
    {
      type: "prepTime",
      value: 1,
    },
    {
     type: "totalTime",
     value: 12,
    }
  ],
  filterClassifications: [
    { id: "9929", type: "PhiladelphiaType" },
    { id: "2086 ", type: "DishType" },
  ]
}

const productIDs = [95, 96]

const redirectList = [
  {
    fromPath: "/philly-difference",
    toPath: "https://www.philadelphiaprofessional.co.uk/",
  },
  {
    fromPath: "/corporate-information",
    toPath:
      "https://www.philadelphiaprofessional.co.uk/corporate-information/corporate-reporting/",
  },
  {
    fromPath: "/corporate-information/contact",
    toPath:
      "https://contactus.mdlzapps.com/form?siteId=mZxEmnBvrkt3Ey1XJ8o8Vpuy6OncMIjle70mYoYdMP4%3d",
  },
  {
    fromPath: "/corporate-information/career",
    toPath: "https://www.mondelezinternational.com/careers/?utm_source=philadelphiaprofessional.co.uk&utm_medium=int&utm_campaign=footer-link",
  },
  {
    fromPath: "/the-philly-five",
    toPath: "https://www.philadelphiaprofessional.co.uk/",
  },
]

module.exports = { mondelezAPI, productIDs, redirectList }