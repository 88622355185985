export const props = {
    "heading": "Sitemap",
    "linksCollection": {
      "items": [
        {
          "label": "",
          "link": {
            "label": "THE PHILLY DIFFERENCE",
            "title": "",
            "url": "/phillydifference/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "THE RANGE",
          "link": {
            "label": "THE RANGE",
            "title": null,
            "url": "/range/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "gaEventClass": "event_menu_click",
            "gaEventLabel": "THE RANGE"
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "THE RECIPES",
          "link": {
            "label": "THE RECIPES",
            "title": null,
            "url": "/recipes/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "gaEventClass": "event_menu_click",
            "gaEventLabel": "THE RECIPES"
          },
          "dropdownItemsCollection": {
            "items": []
          }
        },
        {
          "label": "THE PHILLY FIVE",
          "link": {
            "label": "THE PHILLY FIVE",
            "title": null,
            "url": "/the-philly-five/education/",
            "target": "_self",
            "isAnchorTag": true,
            "scrollToElementId": null,
            "gaEventClass": "event_menu_click",
            "gaEventLabel": "THE PHILLY FIVE"
          },
          "dropdownItemsCollection": {
            "items": [
              {
                "__typename": "Link",
                "label": "EDUCATION",
                "title": null,
                "url": "/the-philly-five/education/",
                "target": "_self",
                "isAnchorTag": true,
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "HOTELS",
                "title": null,
                "url": "/the-philly-five/hotels/",
                "target": "_self",
                "isAnchorTag": true,
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "FOOD-TO-GO",
                "title": null,
                "url": "/the-philly-five/food-to-go/",
                "target": "_self",
                "isAnchorTag": true,
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "RESTAURANTS & PUBS",
                "title": null,
                "url": "/the-philly-five/restaurants-and-pubs/",
                "target": "_self",
                "isAnchorTag": true,
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "HEALTHCARE",
                "title": null,
                "url": "/the-philly-five/healthcare/",
                "target": "_self",
                "isAnchorTag": true,
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              }
            ]
          }
        },
        {
          "label": "CORPORATE INFORMATION",
          "link": {
            "label": "COMPANY DETAILS",
            "title": null,
            "url": "/corporate-information/company-details/",
            "target": "_self",
            "isAnchorTag": null,
            "scrollToElementId": null,
            "gaEventClass": "event_external_link",
            "gaEventLabel": "COMPANY DETAILS"
          },
          "dropdownItemsCollection": {
            "items": [
              {
                "__typename": "Link",
                "label": "CONTACT",
                "title": null,
                "url": "https://contactus.mdlzapps.com/form?siteId=mZxEmnBvrkt3Ey1XJ8o8Vpuy6OncMIjle70mYoYdMP4%3d",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "COMPANY DETAILS",
                "title": null,
                "url": "/corporate-information/company-details/",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "gaEventClass": "event_external_link",
                "gaEventLabel": "COMPANY DETAILS"
              },
              {
                "__typename": "Link",
                "label": "COOKIES INFO",
                "title": null,
                "url": "/corporate-information/cookies-info/",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "gaEventClass": "event_external_link",
                "gaEventLabel": "COOKIES INFO"
              },
              {
                "__typename": "Link",
                "label": "PRIVACY NOTICE",
                "title": null,
                "url": "/corporate-information/privacy-policy/",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "gaEventClass": "event_external_link",
                "gaEventLabel": "PRIVACY NOTICE"
              },
              {
                "__typename": "Link",
                "label": "TERMS OF USE",
                "title": null,
                "url": "/corporate-information/terms-of-use/",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "gaEventClass": "event_external_link",
                "gaEventLabel": "TERMS OF USE"
              },
              {
                "__typename": "Link",
                "label": "CAREERS",
                "title": null,
                "url": "https://www.mondelezinternational.com/careers/?utm_source=philadelphiaprofessional.co.uk&utm_medium=int&utm_campaign=footer-link",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "gaEventClass": null,
                "gaEventLabel": null
              },
              {
                "__typename": "Link",
                "label": "CORPORATE REPORTING",
                "title": null,
                "url": "/corporate-information/corporate-reporting/",
                "target": "_self",
                "isAnchorTag": null,
                "scrollToElementId": null,
                "gaEventClass": "event_external_link",
                "gaEventLabel": "CORPORATE REPORTING"
              }
            ]
          }
        }
      ]
    }
  }