export const defaultStyle = {
  CarouselWrpr: "carouselWrpr min-h-[500px] bg-cover",
  controlBtns: "relative lg:w-[1170px] mx-auto px-15 lg:px-0",
  carouselInnerWrpr: "carouselInnerWrpr",
  title: "title text-[64px] font-bold mt-70 ",
  subTitle: "subTitle text-lightGrey text-[20px] lg:w-[469px]",
  imgCardWrpr: "imgCardWrpr w-1/2",
  textSectionWrpr: "w-1/2",
  SLIDE: "SLIDE",
}

export const FOUR_SLIDES_CAROUSEL = {
  CarouselWrpr: "carouselWrpr homePageCarouselWrpr bg-cover",
  controlBtns: "relative lg:w-[1090px] mx-auto px-15 lg:px-0 max-w-full",
}

export const MULTI_SECTION_CAROUSEL_SLIDE_ONE = {
  CarouselWrpr:
    "MULTI_SECTION_CAROUSEL_SLIDE_TWO carouselWrpr min-h-[500px] bg-cover",
  controlBtns:
    "MULTI_SECTION_CAROUSEL_SLIDE_TWO relative lg:w-[1170px] mx-auto px-15 lg:px-0",
  carouselInnerWrpr: " MULTI_SECTION_CAROUSEL_SLIDE_TWO carouselInnerWrpr",
  title: "title lg:text-[50px] text-[39px] font-bold",
  subTitle:
    " MULTI_SECTION_CAROUSEL_SLIDE_TWO subTitle fourslideSubTtl text-lightGrey text-[20px] lg:w-[469px]",
  imgCardWrpr: "MULTI_SECTION_CAROUSEL_SLIDE_TWO imgCardWrpr lg:w-1/2 ",
  textSectionWrpr:
    "MULTI_SECTION_CAROUSEL_SLIDE_TWO lg:w-1/2 mt-50 lg:pl-45 lg:pt-65",
  SLIDE:
    "MULTI_SECTION_CAROUSEL_SLIDE_TWO SLIDE flex flex-wrap flex-row-reverse",
}
