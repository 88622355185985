import React from "react";
import Image from "../Image/Image";
import {render} from '@testing-library/react'
import {articleCardProps} from '../../../mocks/image.mocks'

describe("Image", () => {
 beforeEach(() => {
    render(<Image {...articleCardProps} />)
 })

 test('src should not be empty and it should a webp image', () => {
    const imageElement = document.querySelector('img')
    expect(imageElement.src).toContain('https://images.ctfassets.net/qc3lz96t0r4x/4DhXT2R1rFUZCdCku1jedS/90cc8d5d496ed874abccf95c9cae650c/sanp2.png?fm=webp&q=80')
 })

 test('should have alt text', () => {
   const imageElement = document.querySelector('img')
   expect(imageElement.alt).toContain('Dean Crews - Hotels Chef')
})
})



