import React from 'react'
import {screen, render, within  } from '@testing-library/react'
import ModalWrapper from './ModalWrapper';
import { ModalWrapperProps } from '../../../mocks/ModalWrapper.mock'

describe('ModalWrapper', () => { 
    render(<ModalWrapper {...ModalWrapperProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<ModalWrapper {...ModalWrapperProps}/>)
    })

    test('should render Children', () => {
        expect(screen.getAllByText("Modal Wrapper"))  
    })

    test('should render Image', () => {
        const imageElement = document.querySelector('img')
        expect(imageElement.src).toContain('https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg') 
    })

})