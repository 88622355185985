export const imageLayoutProps = {
    "heading": "Chefs have spoken:",
    "description": "They choose Philadelphia because it is a creamier, more consistent product with superior 1",
    "variant": "ZIGZAG_IMAGE_LAYOUT_SECTION",
    "imagesCollection": {
        "items": [
            {
                "gaEventClass": "event_image_click",
                "gaEventLabel": "Apple & Caramel Philly Turnovers",
                "image": {
                    "url": "https://images.ctfassets.net/qc3lz96t0r4x/5zc7ibV2OYpD1Nx4V0C2Yh/6ebf83baad421529ee0490eb9b7c8d56/apple.jpg",
                    "title": "apple",
                    "description": "",
                    "width": 400,
                    "height": 400
                },
                "link": {
                    "label": "Link 1",
                    "title": "Link 1",
                    "name": "Link 1",
                    "url": "/recipes/apple-caramel-philly-turnovers-426310/",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "gaEventClass": "event_image_click",
                "gaEventLabel": "Chestnut Mushroom and Nutmeg Lasagne",
                "image": {
                    "url": "https://images.ctfassets.net/qc3lz96t0r4x/1LyBfMEq2QC5DslFLWZjtq/0b693d4d86f1f8bd8f66bfd9c72b3156/398291_96.jpg",
                    "title": "398291 96",
                    "description": null,
                    "width": 400,
                    "height": 400
                },
                "link": {
                    "label": null,
                    "title": null,
                    "url": "/recipes/chestnut-mushroom-nutmeg-lasagne-398299",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "gaEventClass": "event_image_click",
                "gaEventLabel": "Butternut Squash Philly Pasta",
                "image": {
                    "url": "https://images.ctfassets.net/qc3lz96t0r4x/60tVHeabwRRexWaMePHPNV/3e16e5f597ddbf9998f5d4fb58cc61ba/398282_96.jpg",
                    "title": "398282 96",
                    "description": null,
                    "width": 400,
                    "height": 400
                },
                "link": {
                    "label": null,
                    "title": null,
                    "url": "/recipes/butternut-squash-philly-pasta-398282",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "gaEventClass": "event_image_click",
                "gaEventLabel": "Chicken Breast with a Philly and Tarragon Sauce",
                "image": {
                    "url": "https://images.ctfassets.net/qc3lz96t0r4x/4FoT597ekDTeYxIaWoUr3t/43689d61ad0aa1cf156898b0baeff6f3/398315_96.jpg",
                    "title": "398315 96",
                    "description": null,
                    "width": 400,
                    "height": 400
                },
                "link": {
                    "label": null,
                    "title": null,
                    "url": "/recipes/chicken-breast-a-philly-tarragon-398312",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "gaEventClass": "event_image_click",
                "gaEventLabel": "Philly “No Mash” Fish Pie Gratin",
                "image": {
                    "url": "https://images.ctfassets.net/qc3lz96t0r4x/XFESY8nEyDk7quVDG2yri/ec63974b9f956821fdb667bdf27df289/398306_96.jpg",
                    "title": "398306 96",
                    "description": null,
                    "width": 400,
                    "height": 400
                },
                "link": {
                    "label": null,
                    "title": null,
                    "url": "/recipes/philly-no-mash-fish-pie-398305",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            },
            {
                "gaEventClass": "event_image_click",
                "gaEventLabel": "Lean Chicken Salad with Philly Dressing",
                "image": {
                    "url": "https://images.ctfassets.net/qc3lz96t0r4x/1LyBfMEq2QC5DslFLWZjtq/0b693d4d86f1f8bd8f66bfd9c72b3156/398291_96.jpg",
                    "title": "398291 96",
                    "description": null,
                    "width": 400,
                    "height": 400
                },
                "link": {
                    "label": null,
                    "title": null,
                    "url": "/recipes/lean-chicken-salad-philly-dressing-398291",
                    "target": "_self",
                    "isAnchorTag": null,
                    "scrollToElementId": null,
                    "gaEventClass": null,
                    "gaEventLabel": null,
                    "image": null
                }
            }
        ]
    },
   "style": {
    imgMain:"rounded-[25px] w-auto",
    layoutContainer: "imagelist max-w-full relative lg:mx-auto my-0 ",
    heading: "text-center lg:text-4xl text-[40px] lg:w-full w-2/3 mx-auto leading-[48px] text-white pb-40",
    description: "chefDesc text-2xl lg:w-[47%] mx-auto my-0 lg:text-3xl leading-[30px] lg:leading-10 text-center text-white px-10",
    anchor: "anchor",
    listItem: "listItem lg:w-[360px] lg:max-w-full even:pt-[26.3%] mx-auto",
    list: " lg:w-[1170px] w-full mx-auto  max-w-full grid md:grid-cols-3 gap-2 grid-cols-2 md:px-23  lg:mx-auto md:mx-15 px-15 md:px-auto relative top-[40px]",
    textContainer: "bg-darkBlue py-30 text-center "
  }
}