import React from 'react'
import {screen, render, fireEvent } from '@testing-library/react'
import SearchInput from '../SearchInput/SearchInput';
import { SearchInputProps } from '../../../mocks/SearchInput.mock'

describe('Search Input', () => {

    test('should render search input field', () => {
        render(<SearchInput {...SearchInputProps}/>)
        expect(screen.getByPlaceholderText("Search Value")) 
        const SearchElement = document.querySelector('input')
        expect(SearchElement.name).toContain('search-box')
    })

    test('searchTerm', () => {
        render(<SearchInput {...SearchInputProps}/>)
        const searchTerm = document.querySelector('input')
        expect(searchTerm.value).toContain('Value 1')
    })

    test('click search', () => {
        render(<SearchInput {...SearchInputProps}/>)
        const SearchInputElement = document.querySelector('span')
        fireEvent.click(SearchInputElement);
        const searchTerm = document.querySelector('input')
        expect(searchTerm.value).toContain('Value 1')
    })
})