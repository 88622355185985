import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { get } from "../../helpers/utils"
import Button from "../UI/Button/Button"
import Link from "../UI/Link/Link"
import CarouselSlide from "../UI/CarouselSlide/CarouselSlide"
import * as styles from "./ImageCarousel-tw-styles"
import {
  gaLeftlabel,
  gaRightlabel,
  gaSliderClass,
} from "../../constants/gtm.constants"

const ImageCarousel = props => {
  const { variant, slidesCollection, backgroundImage } = props
  const [activeSlide, setActiveSlide] = useState(0)
  const [sliderRef, setSliderRef] = useState({})

  const slides = get(slidesCollection, "items", [])
  const style = styles[variant] || styles.defaultStyle

  const firstSlide = 0
  const lastSlide = slides.length - 1

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    swipeToSlide: true,
    slidesToShow: 1,
    arrows: false,
    accessibility: true,
    beforeChange: (oldIndex, newIndex) => {
      setActiveSlide(newIndex)
    },
    responsive: [
      {
        breakpoint: 769,
        settings: {
          appendDots: dots => <ul>{dots}</ul>,
          dots: true,
        },
      },
      {
        breakpoint: 361,
        settings: {
          dots: false,
        },
      },
    ],
  }

  const [isDisplayed, setIsDisplayed] = useState(false)
  useEffect(() => {
    const interval = setTimeout(() => {
      setIsDisplayed(true)
      clearInterval(interval)
    }, 100)
  })

  return (
    <div
      className={style?.CarouselWrpr}
      style={{ backgroundImage: `url(${backgroundImage?.url})` }}
    >
      <div className={style?.controlBtns}>
        {activeSlide !== firstSlide && (
          <Button
            onClick={() => {
              sliderRef?.slickPrev()
            }}
            id="btnPrev"
            className={
              "icon-left-arrow lg:before:text-[55px] before:text-[27px] transform lg:mt-[18%]  md:mt-[74%] caroslArwImg  mt-[162%] z-[2] absolute ml-[0px] lg:ml-auto"
            }
            dataActionDetail={gaLeftlabel}
            gaEventClass={gaSliderClass}
          />
        )}
        {activeSlide !== lastSlide && (
          <Button
            onClick={() => {
              sliderRef?.slickNext()
            }}
            id="btnNext"
            className={
              "icon-right-arrow lg:before:text-[55px] before:text-[27px] lg:mt-[18%] caroslArwImg md:mt-[74%] mt-[162%] absolute right-[10px] z-[2] ml-[0px] lg:ml-auto"
            }
            dataActionDetail={gaRightlabel}
            gaEventClass={gaSliderClass}
          />
            
        )}
      </div>
      <Slider
        {...settings}
        className={"SLIDER  lg:max-h-500  max-h-[685px]"}
        ref={c => setSliderRef(c)}
      >
        {slides?.length &&
          slides.map((slide, index) => {
            const {
              cardsCollection,
              isContentReversed,
              link,
              text,
              title,
              variant: slideVariant,
            } = slide

            return (
              <div key={index} className={style?.SLIDE}>
                <Link
                  className={`flex ${isContentReversed && "flex-row-reverse lg:w-[1170px] mx-auto"}`}
                  onMouseDown={e => {
                    e.preventDefault()
                  }}
                  isAnchorTag={true}
                  {...link}
                >
                  <CarouselSlide
                    cardsCollection={cardsCollection}
                    variant={slideVariant}
                    slideTitle={title}
                    slideText={text}
                    link={link}
                  />
                </Link>
              </div>
            )
          })}
      </Slider>
    </div>
  )
}

ImageCarousel.propTypes = {
  slidesCollection: PropTypes.object,
  variant: PropTypes.string,
  backgroundImage: PropTypes.object,
}

export default ImageCarousel
