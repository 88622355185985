import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { labels } from "../../../constants/recipeListing.constants"
import SearchInput from "../../UI/SearchInput/SearchInput"
import Button from "../../UI/Button/Button"
import { SEARCH_RECIPES as style } from "./SearchRecipes-tw-styles"
import { updatePageUrl } from "../../../helpers/search"
import { useDispatch, useSelector } from "react-redux"
import { searchRecipeGtm } from "../../../constants/gtm.constants"

// new reducer
import { updateSearchTerm } from "../../../redux/actions/recipesListControlsAction"

const SearchRecipes = props => {
  const recipeListReducer = useSelector(state => state.recipeListReducer)
  const { search } = recipeListReducer
  const [term, setTerm] = useState(search || "")
  const dispatch = useDispatch()

  // set search input field value
  useEffect(() => {
    setTerm(search)
  }, [search])

  const onChange = e => {
    setTerm(e?.target?.value)
  }

  const onSubmit = () => {
    if (typeof window !== "undefined") {
      //data layer push
      if (window?.dataLayer) {
        searchRecipeGtm.GAeventLabel = term
        window.dataLayer.push({ ...searchRecipeGtm })
      }
    }
    updatePageUrl({...recipeListReducer, search:term})
    dispatch(updateSearchTerm(term))
  }
  return (
    <div className={style?.container}>
      <div className={style?.serachWrpr}>
        <SearchInput
          placeholder={labels?.searchPlaceHolder}
          onSearchSubmit={onSubmit}
          style={style?.searchboxStyles}
          searchTerm={term}
          onChange={onChange}
        />
        <Button onClick={onSubmit} className={style?.button}>
          {labels?.searchBtn}
        </Button>
      </div>
    </div>
  )
}
SearchRecipes.propTypes = {}

export default SearchRecipes
