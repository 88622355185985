/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
const ComponentWrapper = props => {
  const { componentName } = props
  //const packages = require(`../adapters/package-module`)
  let Component = null
  // access and render component
  try {
    //check from the package module first
    //Component = packages[componentName]
    // if(!Component) {
    //   // components which are not from the package
    //   Component = require(`../components/${componentName}/${componentName}`).default
    // }
    //add tailwind styles for the components 
    Component = require(`../components/${componentName}/${componentName}`).default
    const styles = require(`../components/${componentName}/${componentName}-tw-styles.js`)
    var style = styles[props.variant] || styles["defaultStyle"]
  } catch {
    console.warn(`Component ${componentName} doesn't exist`)
    Component = null
  }
  return Component ? <Component {...props} style={style} /> : null
}

// components wrapper : maps and renders all components with their respective props from contentful
const ContentfulComponents = props => {
  const { componentsProps = [], pageType } = props
  if (componentsProps.length === 0) {
    return null
  }
  return (
    <>
      {componentsProps.map((prop, index) => {
        if (!prop) {
          return null
        }
        const componentName = Object?.keys(prop)[0]
        return (
          <ComponentWrapper
            componentName={componentName}
            {...prop[componentName]}
            pageType={pageType}
            key={index}
          />
        )
      })}
    </>
  )
}

ContentfulComponents.propTypes = {
  componentsProps: PropTypes.array,
  pageType: PropTypes.string,
}

ComponentWrapper.propTypes = {
  componentName: PropTypes.string,
}

export default ContentfulComponents
