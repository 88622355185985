import React from 'react'
import {screen, render} from '@testing-library/react'
import RichTextModel from './RichTextModel'
import {RichTextModelProps} from '../../mocks/RichTextModel.mock'

describe('RichTextModel', () => { 

    render(<RichTextModel {...RichTextModelProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<RichTextModel {...RichTextModelProps}/>)
    })  

    test('should render RichTextModel component', () => {
        render(<RichTextModel {...RichTextModelProps}/>)
    })

    test('should render Title', () => {
        expect(screen.getByText("Rich Text Model"))
    })
})