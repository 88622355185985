import React from 'react'
import {screen, render} from '@testing-library/react'
import FormContainer from './FormContainer'
import {props} from '../../mocks/FormContainer.mock'

describe('FormContainer', () => { 
    render(<FormContainer {...props}/>)
    let view = null
    beforeEach(() => {
        view = render(<FormContainer {...props}/>)
    })  
    test('should render form component initially', () => {
        expect(screen.getAllByText(/Free Hotels Recipe Book/i)).toBeInTheDocument;
    })  
 })  