import React from 'react'
import {render  } from '@testing-library/react'
import VideoModal from './VideoModal';
import { VideoPlayerProps } from '../../../mocks/VideoPlayer.mock'

describe('VideoModal', () => { 
    render(<VideoModal {...VideoPlayerProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<VideoModal {...VideoPlayerProps}/>)
    })

    test('should render Iframe', () => {
        const iframeElement = document.querySelector('iframe')
        expect(iframeElement.title).toContain('Video Player')
        expect(iframeElement.src).toContain('https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg&autoplay=1')
    })

})