export const props = {
    "name": "PhillyfiveForm hotel",
    "form": {
      "heading": "Free Hotels Recipe Book",
      "sitekey": "",
      "description": "Fill in your details below to download Dean's __full collection of recipes__, including exclusive hints and tips, along with nutritionals and costings for each recipe",
      "jsonEditor": {
        "text": "Not Registered?",
        "submit": {
          "name": "submit",
          "error": "Some information seems incomplete or incorrect. To make corrections please to continue.",
          "label": "Submit"
        },
        "heading": "Free Hotels <strong>Recipe Book</strong>",
        "bookType": 2,
        "variant": "formInputStyle",
        "checkbox": {
          "aggrementCheckbox": {
            "icon": "",
            "name": "aggrementCheckbox",
            "error": "",
            "label": "<p>If you would like to receive future news, recipes, information on trends and new Philadelphia products by email or paper, please tick this box. If you no longer wish to receive emails or paper, you may unsubscribe <a href='https://contactus.mdlzapps.com/form?siteId=mZxEmnBvrkt3Ey1XJ8o8Vpuy6OncMIjle70mYoYdMP4%3d'>here</a>.</p>  <p>We value your trust when sharing your personal data with us. We always treat your data in a fair and respectful manner limited to the purpose above mentioned. If you would like to know more about how we handle your data, please read our <a href='/corporate-information/privacy-policy/'>Privacy Notice</a>.</p>",
            "rules": [
              {
                "type": "CHECKBOX",
                "errorMessage": ""
              }
            ],
            "value": false,
            "isValid": true,
            "fieldType": "checkbox",
            "isRequired": true,
            "submittion": true,
            "elementType": "input",
            "elementConfig": {
              "type": "checkbox",
              "placeholder": ""
            }
          },
          "confirmationCheckbox": {
            "icon": "",
            "name": "confirmationCheckbox",
            "error": "",
            "label": "I confirm that I am over 18 years old*",
            "rules": [
              {
                "type": "CHECKBOX",
                "errorMessage": "Please check"
              }
            ],
            "value": false,
            "isValid": true,
            "fieldType": "checkbox",
            "isRequired": true,
            "submittion": true,
            "elementType": "input",
            "elementConfig": {
              "type": "checkbox",
              "placeholder": ""
            }
          }
        },
        "fieldList": {
          "email": {
            "icon": "",
            "name": "email",
            "error": "",
            "label": "Email",
            "rules": [
              {
                "type": "REQUIRED",
                "errorMessage": "Email Required"
              },
              {
                "type": "EMAIL",
                "errorMessage": "Please enter valid email"
              }
            ],
            "value": "",
            "isValid": true,
            "fieldType": "input",
            "isRequired": true,
            "submittion": true,
            "elementType": "input",
            "elementConfig": {
              "type": "text",
              "placeholder": ""
            }
          },
          "jobTitle": {
            "name": "jobTitle",
            "error": "",
            "label": "Job Title",
            "rules": [
              {
                "type": "REQUIRED",
                "errorMessage": "Job Title Required"
              },
              {
                "type": "OTHERINPUT",
                "errorMessage": "Please provide the value"
              }
            ],
            "value": "",
            "isValid": true,
            "options": [
              {
                "label": "Please choose",
                "value": 0
              },
              {
                "label": "Commis Chef",
                "value": 1
              },
              {
                "label": "Chef de Partie",
                "value": 2
              },
              {
                "label": "Sous Chef",
                "value": 3
              },
              {
                "label": "Head Chef",
                "value": 4
              },
              {
                "label": "Executive Chef",
                "value": 5
              },
              {
                "label": "Other",
                "value": 6
              }
            ],
            "fieldType": "select",
            "showInput": false,
            "isRequired": true,
            "otherInput": {
              "id": "jobTitleInput",
              "name": "jobTitleInput",
              "type": "text",
              "value": "",
              "placeholder": "Please specify"
            },
            "submittion": true,
            "elementType": "select",
            "placeholder": "Please Specify",
            "defaultValue": "",
            "elementConfig": {
              "type": "select",
              "placeholder": ""
            }
          },
          "lastName": {
            "name": "lastName",
            "error": "",
            "label": "Last name",
            "rules": [
              {
                "type": "REQUIRED",
                "errorMessage": "Last name Required"
              }
            ],
            "value": "",
            "isValid": true,
            "fieldType": "input",
            "maxLength": 256,
            "isRequired": true,
            "submittion": true,
            "elementType": "input",
            "elementConfig": {
              "type": "text",
              "placeholder": ""
            }
          },
          "firstName": {
            "name": "firstName",
            "error": "",
            "label": "First name",
            "rules": [
              {
                "type": "REQUIRED",
                "errorMessage": "First name Required"
              }
            ],
            "value": "",
            "isValid": true,
            "fieldType": "input",
            "maxLength": 256,
            "isRequired": true,
            "submittion": true,
            "elementType": "input",
            "elementConfig": {
              "type": "text",
              "placeholder": ""
            }
          },
          "recipeBook": {
            "name": "recipeBook",
            "error": "",
            "label": "Recipe book",
            "rules": [
                {
                    "type": "REQUIRED",
                    "errorMessage": "Select recipe book"
                },
                {
                    "type": "OTHERINPUT",
                    "errorMessage": "Please provide the value"
                }
            ],
            "value": "1",
            "isValid": true,
            "options": [
                {
                    "label": "Please choose",
                    "value": 0
                },
                {
                    "label": "Education",
                    "value": 1
                },
                {
                    "label": "Hotels",
                    "value": 2
                },
                {
                    "label": "Food to Go",
                    "value": 3
                },
                {
                    "label": "Restaurants & Pubs",
                    "value": 4
                },
                {
                    "label": "Healthcare",
                    "value": 5
                }
            ],
            "fieldType": "select",
            "isRequired": true,
            "submittion": true,
            "elementType": "select",
            "elementConfig": {
                "type": "select",
                "placeholder": ""
            }
          },
          "wholeSaler": {
            "icon": "",
            "name": "wholeSaler",
            "error": "",
            "label": "Preferred wholesaler",
            "rules": [
              {
                "type": "input",
                "errorMessage": ""
              }
            ],
            "value": "",
            "isValid": true,
            "fieldType": "input",
            "isRequired": false,
            "submittion": true,
            "elementType": "input",
            "elementConfig": {
              "type": "text",
              "placeholder": ""
            }
          },
          "workSector": {
            "name": "workSector",
            "error": "",
            "label": "Sector you work in",
            "rules": [
              {
                "type": "REQUIRED",
                "errorMessage": "Select work Sector"
              },
              {
                "type": "OTHERINPUT",
                "errorMessage": "Please provide the value"
              }
            ],
            "value": "",
            "isValid": true,
            "options": [
              {
                "label": "Please choose",
                "value": 0
              },
              {
                "label": "Education",
                "value": 1
              },
              {
                "label": "Healthcare",
                "value": 2
              },
              {
                "label": "Restaurant",
                "value": 3
              },
              {
                "label": "Pub",
                "value": 4
              },
              {
                "label": "Cafe",
                "value": 5
              },
              {
                "label": "Food to Go",
                "value": 6
              },
              {
                "label": "Hotel",
                "value": 7
              },
              {
                "label": "Event Catering",
                "value": 8
              },
              {
                "label": "Manufacturing",
                "value": 9
              },
              {
                "label": "Other",
                "value": 10
              }
            ],
            "fieldType": "select",
            "showInput": false,
            "isRequired": true,
            "otherInput": {
              "id": "workSectorInput",
              "name": "workSectorInput",
              "type": "text",
              "value": "",
              "placeholder": "Please specify"
            },
            "submittion": true,
            "elementType": "select",
            "defaultValue": "",
            "elementConfig": {
              "type": "select",
              "placeholder": ""
            }
          },
          "establishmentName": {
            "name": "establishmentName",
            "error": "",
            "label": "Establishment Name",
            "rules": "",
            "value": "",
            "isValid": true,
            "fieldType": "input",
            "isRequired": false,
            "submittion": true,
            "elementType": "input",
            "elementConfig": {
              "type": "text",
              "placeholder": ""
            }
          }
        },
        "dataSiteKey": "6LfDJhkTAAAAAM576sI872c5ll6_6mKE9MZdQDmy",
        "description": "Fill in your details below to download Dean's __full collection of recipes__, including exclusive hints and tips, along with nutritionals and costings for each recipe",
        "reCaptchaError": "Recaptcha validation failed"
      }
    },
    "recipieBookCollection": {
      "items": [
        {
          "__typename": "ImageWithText",
          "heading": "Enjoy the recipes, chef!",
          "description": "__Simply click the button below to start your download__. We have also sent a copy of the recipe book to the email address you provided.",
          "variant": "TEXT_WITH_WHITE_BG",
          "link": {
            "label": "Start download",
            "title": "Start download",
            "url": "https://www.philadelphiaprofessional.co.uk/~/media/philadelphiaprofessional/couk2020/files/pdf/recipe-books/philadelphia-professional-hotels-recipe-book.pdf",
            "target": "_blank",
            "scrollToElementId": null,
            "gaEventClass": "event_file_download",
            "gaEventLabel": "https://www.philadelphiaprofessional.co.uk/~/media/philadelphiaprofessional/couk2020/files/pdf/recipe-books/philadelphia-professional-hotels-recipe-book.pdf",
            "image": null
          }
        },
        {
          "__typename": "Link",
          "label": null,
          "title": "InstagramIcon",
          "url": "https://www.instagram.com/philadelphiaprofessional_ukie/",
          "target": "_blank",
          "imageType": "svg",
          "isAnchorTag": null,
          "scrollToElementId": null,
          "gaEventClass": "event_socialmedia_exit",
          "gaEventLabel": "Instagram",
          "image": {
            "title": "download-i",
            "description": "Instagram Icon",
            "url": "https://images.ctfassets.net/qc3lz96t0r4x/3p63nP2uveFmcrbt5Xphby/f8cfc354deeb435bc160c18092d3453e/clipart2664667.png",
            "width": 504,
            "height": 504
          }
        },
        {
          "__typename": "TextContent",
          "title": null,
          "shortDescription": "<div>Don't forget to follow <span>@philadelphiaprofessional_ukie</span> on Instagram to chat with fellow chefs from your sector.</div>\n"
        }
      ]
    }
  }