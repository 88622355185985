const query = (id, isPreview )=> `
{
    imageHeroBanner(id: "${id}", preview: ${isPreview}) {
        heading
        description
        backLink {
          label
          title
          url
          target
          isAnchorTag
          scrollToElementId
          image {
            title
            description
            url
            width
            height
          }
        }
        image {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        foregroundImage {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        backLink {
          label
          title
          url
          target
          isAnchorTag
          scrollToElementId
          image {
            title
            description
            url
            width
            height
          }
          gaEventClass
          gaEventLabel
        }
        link {
          label
          title
          url
          target
          isAnchorTag
          scrollToElementId
          image {
            title
            description
            url
            width
            height
          }
          gaEventClass
          gaEventLabel
        }
        variant
 
  }
}
`
module.exports = { query }
