export const RichTextModelProps = {
      "name": "Rich Text homepage",
      "variant": "FOOT_NOTE",
      "title": 'Rich Text Model',
      "richText": {
        "json": {
          "nodeType": "document",
          "data": {},
          "content": [
            {
              "nodeType": "paragraph",
              "data": {},
              "content": [
                {
                  "nodeType": "text",
                  "value": "",
                  "marks": [],
                  "data": {}
                },
                {
                  "nodeType": "embedded-entry-inline",
                  "data": {
                    "target": {
                      "sys": {
                        "id": "46xijX3yvtrzT6bNVARkF",
                        "type": "Link",
                        "linkType": "Entry"
                      }
                    }
                  },
                  "content": []
                },
                {
                  "nodeType": "text",
                  "value": "Independent chef insight conducted by Good Sense Research, March 2020.",
                  "marks": [],
                  "data": {}
                }
              ]
            },
            {
              "nodeType": "paragraph",
              "data": {},
              "content": [
                {
                  "nodeType": "text",
                  "value": "",
                  "marks": [],
                  "data": {}
                },
                {
                  "nodeType": "embedded-entry-inline",
                  "data": {
                    "target": {
                      "sys": {
                        "id": "3dGbpkmeXuJAzYIqOGCjne",
                        "type": "Link",
                        "linkType": "Entry"
                      }
                    }
                  },
                  "content": []
                },
                {
                  "nodeType": "text",
                  "value": "89% of chefs stated that Philadelphia Original did not split with heated in a pan. Independent blind tests conducted by Good Sense Research in July 2020 with 101 chefs.",
                  "marks": [],
                  "data": {}
                }
              ]
            },
            {
              "nodeType": "paragraph",
              "data": {},
              "content": [
                {
                  "nodeType": "text",
                  "value": "",
                  "marks": [],
                  "data": {}
                },
                {
                  "nodeType": "embedded-entry-inline",
                  "data": {
                    "target": {
                      "sys": {
                        "id": "2CITsdU55E2wa49wZB2XXR",
                        "type": "Link",
                        "linkType": "Entry"
                      }
                    }
                  },
                  "content": []
                },
                {
                  "nodeType": "text",
                  "value": "93% of chefs stated during blind taste tests that they felt that Philadelphia Original was either creamy or very creamy. Independent research conducted by Good Sense Research in July 2020 with 101 chefs with 3 competitor products.",
                  "marks": [],
                  "data": {}
                }
              ]
            },
            {
              "nodeType": "paragraph",
              "data": {},
              "content": [
                {
                  "nodeType": "text",
                  "value": "",
                  "marks": [],
                  "data": {}
                },
                {
                  "nodeType": "embedded-entry-inline",
                  "data": {
                    "target": {
                      "sys": {
                        "id": "2LctiZ97AfVfBmfALmgl70",
                        "type": "Link",
                        "linkType": "Entry"
                      }
                    }
                  },
                  "content": []
                },
                {
                  "nodeType": "text",
                  "value": "33% of chefs feel Philadelphia Original performed best out of 4 cream cheeses when used in an icing and piped onto a cake which is a majority share versus the other three competitors in the independent research conducted by Good Sense Research in July 2020 with 101 chefs.",
                  "marks": [],
                  "data": {}
                }
              ]
            },
            {
              "nodeType": "paragraph",
              "data": {},
              "content": [
                {
                  "nodeType": "text",
                  "value": "",
                  "marks": [],
                  "data": {}
                },
                {
                  "nodeType": "embedded-entry-inline",
                  "data": {
                    "target": {
                      "sys": {
                        "id": "aVlhKaeleDb0L96ZfqRnD",
                        "type": "Link",
                        "linkType": "Entry"
                      }
                    }
                  },
                  "content": []
                },
                {
                  "nodeType": "text",
                  "value": "86% of chefs said Philadelphia Original's liquid release during the cooking process is just about right or slightly too dry during independent blind tests conducted by Good Sense Research in July 2020 with 101 chefs, performing significantly better than the other 3 competitor products tested.\n\n",
                  "marks": [],
                  "data": {}
                }
              ]
            }
          ]
        },
        "links": {
          "entries": {
            "inline": [
              {
                "sys": {
                  "id": "46xijX3yvtrzT6bNVARkF"
                },
                "__typename": "SuperScript",
                "superScript": "1."
              },
              {
                "sys": {
                  "id": "3dGbpkmeXuJAzYIqOGCjne"
                },
                "__typename": "SuperScript",
                "superScript": "2."
              },
              {
                "sys": {
                  "id": "2CITsdU55E2wa49wZB2XXR"
                },
                "__typename": "SuperScript",
                "superScript": "3."
              },
              {
                "sys": {
                  "id": "2LctiZ97AfVfBmfALmgl70"
                },
                "__typename": "SuperScript",
                "superScript": "4."
              },
              {
                "sys": {
                  "id": "aVlhKaeleDb0L96ZfqRnD"
                },
                "__typename": "SuperScript",
                "superScript": "5."
              }
            ]
          }
        }
    },
    "link": null,
    "style": {
        richTextWrapper: "text-left mb-20px lg:w-[972px] lg:my-[45px]  m-10 lg:mx-auto lg:my-[80px]  my-0 opacity-50 text-15"
    }
}