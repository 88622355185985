import React from 'react'
import {screen, render} from '@testing-library/react'
import { social } from '../../../mocks/socialLink.mock'
import SocialLinks from '../SocialLinks/SocialLinks'
describe('social links', () => { 

    render(<SocialLinks {...social}/>)
    let view = null
    beforeEach(() => {
        view = render(<SocialLinks{...social}/>)
    })  
    test('should render component', () => {
        render(<SocialLinks {...social}/>)
    })
    test('should render social icons', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })
   
})