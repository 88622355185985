const query = id => `
{
  videoTabsLayout(id: "${id}") {
    title
    description
    headerText
    phillyLogo{
      url
    }
    bgImage{
      url
    }
    videosTabCollection{
      items{
        title
        description
        link{
          title
          image{
            url
          }
        }
        cardsCollection(limit:12){
          items{
            title
            youtubeVideoId
            thumbnailImage{
              url
            }
            icon{
              url
            }
            slug{
              url
              label
              title
            }
          }
        }
      }
    }
    leftButtonBackgroundImage{
      url
    }
    leftTopBackgroundImage{
      url
    }
    rightButtomBackgroundImage{
      url
    }
    rightTopBackgroundImage{
      url
    }
  }
}`

module.exports = { query }