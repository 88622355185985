export const VideoPlayerProps = {
    posterImage: {
      url: 'https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg',
      title: 'tart',
      description: null,
      width: 900,
      height: 506,
    },
    title: "Video Player", 
    isIframe: true,
    src: "https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg",
    youtubeVideoId: "K4PWIDfd8SY",
    isModalOpen: true,
    closeVideoModal: function() {console.log('close')},
}