import { ListingSteps } from "./ListingSteps";
import React from 'react'
import {screen, render,within} from '@testing-library/react'
import { recipie } from '../../mocks/ListingSteps.mocks'


describe('ListingSteps', () => { 
    render(<ListingSteps {...recipie}/> )
    let view = null
    beforeEach(() => {
        view = render(<ListingSteps {...recipie}/>)
    }) 
    test('should render component', () => {
        expect(screen.getAllByText("The makings of the")).toBeInTheDocument
    })
  

    test('number of recipiesteps', () => {
        const list = screen.getByRole("list")
        const { getAllByRole } = within(list)
        const items = getAllByRole("listitem")
        expect(items.length).toBe(3)

    })
   
    test('recipiesteps renders ', () => {
         expect(screen.getAllByRole('list', {PreparationDescriptionItem:'Mix the panko breadcrumbs, parsley and parmesan together in a small bowl for later.'}))
       
    })
})
