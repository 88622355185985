export const styles = {
    videoSliderWrpr: "lg:px-auto md:w-[750px]  lg:min-h-480 lg:px-15 ml-auto mr-auto float-none clear-both relative w-full philly-five-intro",
    mainSliderContainer: "mainSliderContainer bg-darkBlue pb-40",
    slider: "slider lg:w-[1170px] w-full mx-auto pt-50",
    dotsStyleActive: "cursor-pointer text-3xl p-1",
    dotsStyleInActive: "cursor-pointer text-3xl p-1",
    dotStyleContainer: "flex items-center justify-center",
    sliderThumbContainer: "flex px-0 py-2 w-[2898px]",
    sliderThumbActive: "cursor-pointer w-[207px] h-full border-solid border-4 border-[#00358e] rounded-20 child:rounded-20 mx-10 my-4",
    sliderThumbInActive: "cursor-pointer w-[207px] h-full rounded-20 child:rounded-20 mx-10 my-4",
    dotsContainerStyles: "flex justify-center text-white left-[45%]",
    arrowContainers: "arrowContainers lg:w-[1170px] w-full mx-auto ",
    sliderVideo: "slider-card w-[326px] h-[326px]",
    videoCardTitle: "top-[250px] slider-card-title",
    videoSliderDesc: "lg:text-3xl md:text-[23px] text-[21px] text-lightGrey leading-40 pt-10 pb-30 px-0 md:px-30 lg:px-0 text-center tracking-[0]",
    videoSLiderHeading: "text-[40px] leading-[50px] text-center font-ProxNarrow px-[19px] lg:px-0 tracking-[1px]",
    videoSliderTitle: " text-[36px] lg:text-[40px]",
    boldHeading: "font-bold text-[40px]",
    richTextWrapper: "sm:px-8 PhillyFiveHdng richTextWrapper pb-[25px] lg:w-[725px] text-[21px] lg:text-[25px] lg:mb-[45px] lg:mx-auto mx-[2%] my-0 text-center mt-40 tracking-[0]",
    btnWrpr: "mt-20 buttonEffect2 ",
    title: "title",
    Heading: "text-[40px]",
    mainLink: "buttonEffect2 lg:mt-20 ",
    contentDesc: "lg:text-[25px] text-[21px] leading-8 pt-3 mb-30 text-[#7f7f7f]",
}
export const imageStyle = {
    imgTxtContainer: "lg:px-auto w-full lg:w-[720px] lg:min-h-[480px] mx-auto float-none clear-both relative max-w-full",
    imgWrapper: "relative overflow-hidden rounded-20 max-w-full w-full",
    textImg: "rounded-20 md:h-auto h-[360px] md:max-w-full max-w-[2000px] w-auto relative left-[50%] translate-x-[-50%] lg:min-h-[480px] object-cover",
    textContent: "",
    textHead: "",
    textDesc: "",
    textLink: "",
    imgBtn: "",
    textWrpr: "text-center absolute left-0 bottom-0 w-full pt-[90px] pb-[21px] px-10 lg:text-[31px] text-[21px] lg:leading-10 text-white rounded-b-20 bg-videoGradient tracking-[0] svgText",
    gradBg: 'hidden'
}

export const videoSliderStyles = {
    mainDiv: "relative",
    videoBg: "absolute w-full videoBg",
    leftWrpr: "lg:max-w-[17%]  lg:top-0 lg:left-0 lg:absolute w-[250px] mx-auto my-0",
    leftImg: "lg:h-auto h-[155px]",
    rightWrprDt: "max-w-[17%] top-0 right-0 absolute",
    rightWrpr: "w-[285px] mx-auto my-0",
    videoSliderWrpr: "w-full lg:px-auto lg:w-[750px] w-[360px] lg:min-h-446 lg:px-15 ml-auto mr-auto float-none clear-both relative w-full philly-five-intro",
    mainSliderContainer: "mainSliderContainer bg-darkBlue pb-40",
    slider: "slider lg:w-[1170px] w-full mx-auto pt-50",
    dotsStyleActive: "cursor-pointer text-3xl p-1",
    dotsStyleInActive: "cursor-pointer text-3xl p-1",
    dotStyleContainer: "flex items-center justify-center",
    sliderThumbContainer: "flex px-0 py-2 w-[2898px]",
    sliderThumbActive: "cursor-pointer w-[207px] h-full border-solid border-4 border-[#00358e] rounded-20 child:rounded-20 mx-10 my-4",
    sliderThumbInActive: "cursor-pointer w-[207px] h-full rounded-20 child:rounded-20 mx-10 my-4",
    dotsContainerStyles: "flex justify-center text-white left-[45%]",
    arrowContainers: "arrowContainers lg:w-[1170px] w-full mx-auto ",
    sliderVideo: "slider-card w-[326px] h-[326px]",
    videoCardTitle: "top-[250px] slider-card-title",
    videoSliderDesc: "lg:text-3xl text-[21px] text-lightGrey leading-40 pt-10 pb-30 text-center",
    videoSLiderHeading: "lg:text-[40px] text-3xl text-center",
    videoSliderTitle: " text-[36px] lg:text-[40px]",
    boldHeading: "font-bold text-[40px]",
    sliderMob: "sliderMob",
    mainSliderContainerMargin: "mt-[72px]",
    cardSliderOverflow: "overflow-hidden",
    sliderClass: "slider",
    btnImg: "w-full relative lg:w-770 rounded-15",
    playBtnImg: "rounded-[20px] absolute top-0",
    videoCntr: "videocontainer relative lg:min-h-[405px] max-w-full",
    playButton: "absolute bg-arrowBg lg:top-[170px] md:top-[170px] left-[47%] rounded-full md:mt-0 lg:mt-auto mt-[15%] ml-[-20px] lg:ml-auto",
    showImg: "w-full h-full ",
    hideImg: "hidden",
    videoFrame: "lg:h-[403px] videoCrsl w-[717px] child:rounded-20 lg:h-full child:w-full child:mx-auto child:my-0 mb-10",
    videoFrameHide: "lg:px-10 lg:px-0 lg:h-[405px] w-[720px] child:rounded-20 child:h-full w-full child:mx-auto child:my-0 lg:pb-40 max-w-full flex",
}