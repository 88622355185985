import React from 'react'
import {screen, render} from '@testing-library/react'
import IconButton from './IconButton'
import {IconButtonProps} from '../../mocks/IconButton.mock'

describe('IconButton', () => { 
    
    render(<IconButton {...IconButtonProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<IconButton {...IconButtonProps}/>)
    })

    test('should render component', () => {
        render(<IconButton {...IconButtonProps}/>)
    })

    test('should render Image', () => {
        const imageElement = document.querySelector('img')
        expect(imageElement.src).toContain('https://images.ctfassets.net/qc3lz96t0r4x/2IagnMhmxNVx6IRh4CUtiL/3fdd980773c172824dea6b604e771b61/round-arrow-blue.png')
    })

    test('should render Image text', () => {
        expect(screen.getAllByText("Spread the word, chef!"))  
    })

    test('should render Instagram Icon', () => {
        const instaElement = document.querySelector('a')
        expect(instaElement.title).toContain('Instagram')
        expect(instaElement.href).toContain('https://www.instagram.com/philadelphiaprofessional_ukie/')
    })

 })