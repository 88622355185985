import React from 'react'
import {screen, render} from '@testing-library/react'
import ImageCardsLayout from './ImageCardsLayout'
import {props, withSlider} from '../../mocks/ImageCardsLayout.mock'

describe('ImageCardsLayout Without Slider', () => { 
    render(<ImageCardsLayout {...props}/>)
    let view = null
    beforeEach(() => {
        view = render(<ImageCardsLayout {...props}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText("Rendered")).toBeInTheDocument;
    })
    test('should render Image cards', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })
    test('should render Links', () => {
        expect(screen.getAllByText("testlink")).toBeInTheDocument
    })
 })

 describe('ImageCardsLayout With Slider', () => { 
    render(<ImageCardsLayout {...withSlider}/>)
    let view = null
    beforeEach(() => {
        view = render(<ImageCardsLayout {...withSlider}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText("Rendered")).toBeInTheDocument;
    })
    test('should render slider', () => {
        expect(view.container.getElementsByClassName('slick-slider slick-initialized').length).toBe(1);    
    })
    test('should render Image cards', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })
    test('should render Links', () => {
        expect(screen.getAllByText("testlink")).toBeInTheDocument
    })
 })