import React, { useState } from "react"
import PropTypes from "prop-types"
import Form from "../UI/Form/Form"
import SocialLinks from "../UI/SocialLinks/SocialLinks"
import Typography from "../UI/Typography/Typography"
import { filterByKey } from "../../helpers/utils"
import ImageWithText from '../ImageWithText/ImageWithText'
import {TEXT_WITH_WHITE_BG} from '../ImageWithText/ImageWithText-tw-styles'
import Link from "../UI/Link/Link"

const FormContainer = props => {
  const { form, recipieBookCollection } = props
  const dataItems = recipieBookCollection && recipieBookCollection.items

  const instaLink = filterByKey(dataItems, "__typename", "Link")
  const textcontent = filterByKey(dataItems, "__typename", "TextContent")
  const formDownloadData = filterByKey(dataItems, "__typename", "ImageWithText")

  const [isformSubmitted, setSubmission] = useState(false)

  const displayRecepieBook = isSucess => {
    isSucess && setSubmission(true)
    if(isSucess) {
      const element = document.getElementById("formContainer")
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <div className="form" id="formContainer">
      <div className="">
        {!isformSubmitted && (
          <div className="">
            <Form
              {...form}
              formSubmission={displayRecepieBook}
            ></Form>
          </div>
        )}
        <div>
          {isformSubmitted && (
            <div className="bg-darkBlue text-[18px] leading-[26px] xl:text-[26px] xl:leading-[30px] text-white text-center px-20 py-50">
                <p className="xl:w-[80%] mx-auto">&rsquo;Thank you for signing up and welcome to The Philly Academy!
                <br/><br/>
                You have successfully been enrolled in The Philly Academy where you can access a host of free, educational online video resources including advice, hints, tips and genius hacks from chefs, key associations and charities.
                <br/><br/>
                Thanks to your sign up we have been able to donate £1 to Hospitality Action and support the vital work they do to help all those who work or have worked within hospitality in the UK.<br/><br/><Link className={'underline'} url = "/the-philly-academy"> Click here</Link> for more inspiration, hints and tips!&rsquo;
                </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

FormContainer.propTypes = {
  form: PropTypes.object,
  recipieBookCollection: PropTypes.object,
}

export default FormContainer
