export const imageWithTextProps = {
    "heading": "It's time to get inspired!",
    "description": "Hello Chef, you may not know it, but there’s so much more to Philadelphia than cheesecakes and bagels.",
    "image": {
      "title": "Recipe Book",
      "description": "",
      "contentType": "image/png",
      "fileName": "recipe-book.png",
      "size": 210956,
      "url": "https://images.ctfassets.net/qc3lz96t0r4x/2TF3mHdmGyQRW3ixhfdrAB/f1246a043b0d77bdb9a648197ce0d10c/recipe-book.png",
      "width": 600,
      "height": 437
    },
    "link": {
      "name": "Recipe Book Link",
      "url": "/the-philly-five/education/",
      "label": "Select your sector and download your free recipe book",
      "title": null,
      "target": "_self",
      "isAnchorTag": null,
      "scrollToElementId": null,
      "imageType": null
    },
    "style": {
      imgTxtContainer: " lg:w-[1170px] flex flex-wrap flex-row-reverse lg:flex-nowrap items-center justify-between max-w-full my-0 mx-auto relative",
      imgWrapper: "lg:mx-[20px] lg:my-auto mx-auto mt-0 mb-10 w-[45%] flex items-center",
      textImg: "w-full ",
      textContent: "text-lg leading-7 text-center w-[354px] m-w-[354px] text-[#7f7f7f] mx-auto my-auto",
      textWrpr:"lg:w-[448px] lg:max-w-full mx-auto text-center",
      textHead: "text-[#00358e] text-3xl mb-20 font-bold mx-auto",
      textDesc: "text-[#7f7f7f] text-[23px] mb-20",
      textLink: "text-sm buttonEffect2",
      imgBtn: "",
      bgImg:"absolute child:h-[384px]"
    }
  }