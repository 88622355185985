import React from 'react'
import {screen, render} from '@testing-library/react'
import ImageWithText from './ImageWithText'
import {imageWithTextProps} from '../../mocks/ImageWithText.mock'

describe('ImageWithText', () => { 
    render(<ImageWithText {...imageWithTextProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<ImageWithText {...imageWithTextProps}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText("It's time to get inspired!")).toBeInTheDocument
    })
    test('should render Image', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })

    test('should render Link', () => {
        expect(screen.getAllByText("Select your sector and download your free recipe book")).toBeInTheDocument
    })
 })