import React, { useState, useEffect } from "react"
import { styles } from "./VideoTabsLayout-tw-styles"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import ImageCard from "../UI/ImageCard/ImageCard"
import Link from "../UI/Link/Link"
import Image from "../UI/Image/Image"


const VideoTabsLayout = props => {
  const { title, description, headerText, phillyLogo, videosTabCollection,
     leftButtonBackgroundImage, leftTopBackgroundImage, rightButtomBackgroundImage,
     rightTopBackgroundImage } = props

  const [visibleTab, setVisibleTab] = useState("")

  useEffect(() => {
    if (typeof window !== "undefined" ) {
      let tabTitle = window.location.href.includes('Chef') ? 'Chef Life' : 'Philly Hacks'
      setVisibleTab(tabTitle)
    }
  }, [])

  const listTitles = videosTabCollection.items.map((item, index) => (
    <div
      style={{
        backgroundImage: `url(${item.link.image.url})`,
      }}
      key={index}
      onClick={() => setVisibleTab(item.title)}
      className={[
        styles.btnContainer,
        visibleTab === item.title ? " active" : "",
      ]}
    >
      <strong className={styles.tabTitle}>{item.title}</strong>
      <span className={styles.tabBlueMask}></span>
    </div>
  ))
    
    // <img src={item.link.image.url} className={styles.btnImg} />

  const listContent = videosTabCollection.items.map((item, index) => (
    <div
      key={index} className={styles.sectionContainer}
      style={visibleTab === item.title ? {} : { display: "none" }}
    >
      {item.title && (
        <Typography className={styles?.heading2} content={item.title} />
      )}
      {item.description && <Typography
        className={styles?.description2}
        allow_br_tag={true}
        content={item.description}
      />}
      <div className={styles.cardsContainer}>
        {item.cardsCollection.items.map((item, index) => (
          <Link url={item.slug.url} key={index}>
            <ImageCard
              title={item.title}
              image={item.thumbnailImage}
              variant={"FOUR_COLUMN_VIDEO_CARDS"}
              icon={item.icon}
            />
          </Link>
        ))}
      </div>
    </div>
  ))

  return (
    <>
      <div className={styles.container}>
        <div className={styles?.cardsWrapr}>
        {title && <Typography className={styles?.heading} content={title} />} 
          <div className={styles.btnWrpr}>
            <div className={styles?.wrapper}>
              {description && (
              <Typography
                className={styles?.description}
                allow_br_tag={true}
                content={description}
              />
              )}
              {headerText && (<Typography
              className={styles?.phillyTabDesc}
              allow_br_tag={true}
              content={headerText}
              />)}
              {phillyLogo && (<Image
                contentfulImage={phillyLogo} className={styles?.phillyLogo}
              />)}
            </div>
            {listTitles}
          </div>
        </div>
      </div>
      <div className={styles?.tabWrapper}>
        <div className={styles?.phillyImage1}>
          {leftTopBackgroundImage && (<Image
            contentfulImage={leftTopBackgroundImage}
          />)}
        </div>
        <div className={styles?.phillyImage2}>
          {leftButtonBackgroundImage && (<Image
            contentfulImage={leftButtonBackgroundImage}
          />)}
        </div>
        <div className={styles?.phillyImage3}>  
          {rightTopBackgroundImage && (<Image
            contentfulImage={rightTopBackgroundImage}
          />)}
        </div>
        <div className={styles?.phillyImage4}>
          {rightButtomBackgroundImage && (<Image
            contentfulImage={rightButtomBackgroundImage}
          />)}
        </div>
        {listContent}
      </div>
    </>
  )
}

export default VideoTabsLayout

VideoTabsLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  headerText: PropTypes.string,
  phillyLogo: PropTypes.object,
  videosTabCollection: PropTypes.object,
  leftButtonBackgroundImage: PropTypes.object,
  leftTopBackgroundImage: PropTypes.object,
  rightButtomBackgroundImage: PropTypes.object,
  rightTopBackgroundImage: PropTypes.object
}
