import React from 'react'
import {screen, render} from '@testing-library/react'
import RecipesList from './RecipesList'
import {props} from '../../../mocks/RecipeList.mock'

describe('RecipesList', () => { 
    render(<RecipesList {...props}/>)
    let view = null
    beforeEach(() => {
        view = render(<RecipesList {...props}/>)
    })  
    test('should render Image Cards', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument    
    })
 })