import React from "react"
import { screen, render } from "@testing-library/react"
import TextContent from "./TextContent"
import { textcontent } from "../../mocks/TextContent.mock"

describe("TextContent", () => {
  render(<TextContent {...textcontent}/> )
  let view = null
  beforeEach(() => {
      view = render(<TextContent {...textcontent}/>)
  })
  test('should render component', () => {
      expect(screen.getAllByText("cookies Notice")).toBeInTheDocument
  })
  test('should render shortDescription', () => {
  expect(screen.queryByText(" <h2> THIRD PARTY WEBSITES COOKIES </h2>"  )).toBeInTheDocument
  })
  test('should render Typography Element', () => {
      expect(screen.getByTestId("typography"  )).toBeInTheDocument
      })
})
