import React from 'react'
import {screen, render, fireEvent} from '@testing-library/react'
import Form from '../Form/Form'
import {mockData} from '../../../mocks/Form.mock'

describe.skip('Form', () => { 
    render(<Form {...mockData}/>)
    let view = null
    beforeEach(() => {
        view = render(<Form {...mockData}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText(/Free Hotels Recipe Book/i)).toBeInTheDocument;
    })  
    test('should render input field', () => {
        const input = document.querySelector('input')
        expect(input.value).toContain('First Name')
    }) 
    test('should render email ', () => {
        const input = document.querySelector('#email')
        fireEvent.click(input)
        expect(input.value).toContain('xxx@gmail.com')
    })  
    test('should render checkbox fields', () => {
        const fields = document.querySelector("#aggrementCheckbox")
        expect(fields.value).toContain("true")
    }) 
    test('should render submit button', () => {
        expect(screen.getAllByText("Submit")).toBeInTheDocument
    })     
 })  