import React from 'react'
import { render  } from '@testing-library/react'
import InlineView from './InlineView';
import { InlineViewProps } from '../../../mocks/InlineView.mock'

describe('InlineView', () => { 
    render(<InlineView {...InlineViewProps}/>)
    let view = null
    beforeEach(() => {
        view = render(<InlineView {...InlineViewProps}/>)
    })

    test('should render Poster Image', () => {
        const imageElement = document.querySelector('img')
        expect(imageElement.src).toContain('https://images.ctfassets.net/qc3lz96t0r4x/6heqo7tHXK5qBWtU2mNObg/055c681adac9cebd93df93a34eae1c9b/tart.jpg') 
    })

})