import React from 'react'
import {screen, render,within} from '@testing-library/react'
import NutrientInfo from "./NutrientInfo";
import { nutrientinfo } from '../../mocks/nutrientInfo.mocks';



describe('Nutrient Info', () => { 
    render(<NutrientInfo {...nutrientinfo}/>)
    let view = null
    beforeEach(() => {
        view = render(<NutrientInfo {...nutrientinfo}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText(/Chocolate, Philly and Avocado Pots/i)).toBeInTheDocument;
    })
    test('should render Image', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })
    test('Table count', () => {
        expect(screen.getAllByRole('table').length).toBe(1)
    })
    test('Header count', () => {
        const rows = screen.getByRole("table")
        const { getAllByRole } = within(rows)
        const header = getAllByRole("columnheader")
        expect(header.length).toBe(2)
    })
    test('Row count', () => {
        const rows = screen.getByRole("table")
        const { getAllByRole } = within(rows)
        const row = getAllByRole("row")
        expect(row.length).toBe(7)
    })
})