export const articleCardProps = {
    contentfulImage: {
        "title": "Dean Crews",
        "description": "Dean Crews - Hotels Chef",
        "contentType": "image/png",
        "fileName": "sanp2.png",
        "size": 350437,
        "url": "https://images.ctfassets.net/qc3lz96t0r4x/4DhXT2R1rFUZCdCku1jedS/90cc8d5d496ed874abccf95c9cae650c/sanp2.png",
        "width": 498,
        "height": 682
    },
    className: "h-min overflow-hidden rounded-[2rem] borderActivepx-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] grid lg:w-[180px]"
  }