import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "../UI/Button/Button"
import { TOGGLE_PASSWORD } from "../../constants/rules.constants"
import RenderField from "../../helpers/RenderField"
import { validate } from "../../helpers/FormValidations"
import * as styles from "./Signup-tw-styles"
import Image from "../UI/Image/Image"
import Link from "../UI/Link/Link"

const SignUp = props => {
  const {
    heading,
    text,
    fields,
    termsText,
    signUp,
    createAccount,
    forgetPassword,
    variant,
    image,
    description,
    term,
  } = props

  const style = styles[variant] || styles.defaultStyle
  const [form, setForm] = useState(fields)
  const [hidePassword, setHidePassword] = useState(true)
  const [error, setError] = useState({
    email: "",
    password: "",
  })

  const handleChange = (event, id) => {
    if (id === TOGGLE_PASSWORD) {
      setHidePassword(!hidePassword)
    } else {
      const validateField = validate({ ...form[id], value: event.target.value })
      setError(err => ({
        ...err,
        [validateField.name]: validateField.error,
      }))
      setForm(field => ({
        ...field,
        [id]: { ...field[id], value: event.target.value },
      }))
    }
  }

  const handleSubmit = () => {
    const validateField = form
    const isFormValid = Object?.entries(form)?.map(([key, value]) => {
      validateField[key] = validate(value)
      setError(err => ({ ...err, [value.name]: value.error }))
      return validateField[key].isValid
    })
    if (!isFormValid.includes(false)) {
      // TO DO
    }
  }

  return (
    <div className={style?.wrapper}>
      {image ? (
        <div className={style?.imageWrapper}>
          <Image contentfulImage={image} />
        </div>
      ) : (
        ""
      )}

      <div className={style?.contentWrapper}>
        {heading && <div className={style?.heading}>{heading}</div>}

        {description && <p className={style?.description}>{description}</p>}

        <div>
          {Object?.entries(form)?.map(([key, value]) => (
            <RenderField
              key={key}
              id={key}
              field={value}
              onBlur={handleChange}
              handleChange={handleChange}
              hidePassword={hidePassword}
              error={error}
              variant={variant}
            />
          ))}
        </div>
        {forgetPassword && (
          <Link {...forgetPassword} className={style?.resetPassword} />
        )}

        {term && <div>{term}</div>}
        {signUp && (
          <Button
            text={signUp.label}
            type="secondary"
            onClick={handleSubmit}
            className={style?.signUpBtn}
          />
        )}
        {createAccount && (
          <div>
            <p>{text}</p>
            <Link {...createAccount} className={style?.createAcc} />
          </div>
        )}
        {termsText && <div>{termsText}</div>}
      </div>
    </div>
  )
}

SignUp.propTypes = {
  heading: PropTypes.string,
  fields: PropTypes.object,
  text: PropTypes.string,
  termsText: PropTypes.string,
  signUp: PropTypes.object,
  createAccount: PropTypes.object,
  forgetPassword: PropTypes.object,
  term: PropTypes.string,
  image: PropTypes.object,
  variant: PropTypes.string,
  description: PropTypes.string,
}

export default SignUp
