export const ArticleVideoProps ={
    heading: 'Article Video',
    description: 'It is time to get to know the UK no.1 cream cheese',
    video: {
        title: null,
        sitecoreVideo: {
            url: "https://videos.ctfassets.net/qc3lz96t0r4x/3erAwLNrWEBwjvUQxxZ0oD/4b019d26e1d9f823b0c84da449561bd6/file_example_MP4_480_1_5MG.mp4",
            width: null,
            height: null,
            title: "sample video",
            description: null
        },
        youtubeVideoId: "eZ63XVgSOO4",
        posterImage: {
            url: "https://images.ctfassets.net/qc3lz96t0r4x/4zXqBh51IzmT5tc5bxliVu/61a90d79c83c91a54c7067472e59b64f/milk-tea-recipe.jpg",
            width: 500,
            height: 375,
            title: "tea-2",
            description: ""
        },
        "isModal": false
        },
    isContentReversed: true
}