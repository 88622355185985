import React, { useState } from "react"
import { styles } from "./SliderTailWind-tw-styles"
import Image from "../UI/Image/Image"
import PropTypes from "prop-types"

const ImageSlider = props => {
  const { slides } = props
  const { sliderWrpr, sliderImg } = styles
  const [current, setCurrent] = useState(0)
  const length = slides?.length

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null
  }

  return (
    <section className={sliderWrpr}>
      <div className="relative">
        <button
          disabled={current === 0}
          onClick={prevSlide}
          className={
            "icon-left-arrow transform absolute left-15 bg-white lg:bg-transparent p-3 zMinus bg-opacity-50 top-80 lg:top-27 hidden lg:block lg:top-270"
          }
        />
          
        <button
          disabled={current >= length - 1}
          onClick={nextSlide}
          className={
            "icon-right-arrow absolute right-15 lg:top-270 top-80 bg-white lg:bg-transparent p-3 bg-opacity-50 zMinus hidden lg:block"
          }
        />
          
      </div>
      {slides.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && (
              <Image
                src={slide.image}
                alt={slide.alt}
                width={slide.width}
                height={slide.height}
                className={sliderImg}
              />
            )}
          </div>
        )
      })}
    </section>
  )
}

ImageSlider.propTypes = {
  slides: PropTypes.array,
}

export default ImageSlider
