import React from 'react'
import {screen, render} from '@testing-library/react'
import Footer from './Footer'
import {props} from '../../../mocks/Footer.mock'

describe('Footer', () => { 
    render(<Footer {...props}/>)
    let view = null
    beforeEach(() => {
        view = render(<Footer {...props}/>)
    })  
    test('should render component', () => {
        expect(screen.getAllByText(/Philadelphia is available nationwide via all good wholesalers/i)).toBeInTheDocument;
    })
    test('should render social icons', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })
    test('should render main links', () => {
        expect(screen.getAllByText(/CONTACT/i)).toBeInTheDocument;
        expect(screen.getByText(/CONTACT/i).href).toBe("https://contactus.mdlzapps.com/form?siteId=mZxEmnBvrkt3Ey1XJ8o8Vpuy6OncMIjle70mYoYdMP4%3d")
    })
    test('should render brand links', () => {
        expect(screen.getAllByRole(/presentation/i)).toBeInTheDocument
    })
 })