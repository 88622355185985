const query = (id, isPreview) => `
{
  formContainer(id: "${id}", preview: ${isPreview}) {
    name
    form {
      heading
      description
      jsonEditor
    }
    recipieBookCollection {
      items {
        __typename
        ... on ImageWithText {
          heading
          description
          variant
          link {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
            image {
              title
              description
              url
              width
              height
            }
          }
        }
        ... on Link {
          label
          title
          url
          target
          imageType
          isAnchorTag
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            title
            description
            url
            width
            height
          }
        }
        ... on TextContent {
          title
          shortDescription
        }
      }
    }
  }
}

  

  `
module.exports = { query }
