  
export const styles = {
  container:"bg-darkBlue text-white py-50 lg:pt-[44px] lg:pb-40 phillyfivecards relative z-10",
  sectionContainer:"md:pt-50 pb-30 md:min-h-[980px]",
  cardsContainer: "md:grid md:grid-cols-2 lg:grid-cols-4 pb-15 md:py-15 justify-center align-center mx-auto xl:w-[1140px] mb-30",
  heading: "philly-tab-title text-[40px] lg:text-[80px] text-white text-center w-full lg:w-3/4 font-PhiladelphiaRegular lg:mx-auto px-15 [&>h2]:leading-[49px] tracking-wide:leading-[50px]",
  heading2: "text-[30px] md:text-[40px] xl:text-[50px] mt-[20px] mb-1 text-center w-full lg:w-3/4 font-PhiladelphiaBold lg:mx-auto px-15 [&>h2]:leading-[49px] tracking-wide:leading-[50px]",
  description: "philyFiveCard philyFiveCrd items-center text-[18px] md:w-[955px] flex-col flex w-full  text-white px-auto mx-auto pb-20 text-center justify-center leading-30 px-10 tracking-[0]",
  phillyTabDesc: "text-[17px] leading-5 w-full text-white pb-10 mb-40 text-center px-20 tracking-[0]",
  description2:"philyFiveCrd items-center text-[20px] xl:w-[955px] flex-col flex w-full text-lightGrey lg:text-[25px] px-auto mx-auto pb-20 text-center justify-center leading-[26px] md:leading-[30px] px-[10%] md:px-[20%] lg:px-[30%] xl:px-10 tracking-[0]",
  phillyLogo: "px-10",
  btnWrpr: "philly-tabs-wrapper flex flex-wrap lg:flex-nowrap justify-center",
  btnContainer:"relative tab-btn bg-cover mx-[12px] flex justify-end items-center pb-25 font-bold flex-col gap-2 cursor-pointer border-solid border cursor-pointer block text-[24px] leading-[24px] md:text-[35px] md:leading-[35px] my-0 mx-auto text-center w-[42%] md:w-[320px] h-[220px] md:h-[387px] max-w-[100%] border-darkBlue transition-all rounded-[30px] my-15 border-white border-solid border-1 hover:border-2 text-white hover:text-white hover:bg-darkBlue event_button_click ",
  tabTitle: "z-50",
  tabBlueMask: "absolute left-0 bottom-0 w-[100%] rounded-[30px] h-[65px] bg-toTopGradientBlue",
  btnImg: "w-[135px]",
  wrapper: "w-full lg:w-[380px] order-2 md:order-2 mx-15 my-15",
  tabWrapper: "relative",
  phillyImage1: "absolute left-0 top-[-35px] md:top-[-30px] xl:top-[-60px] w-[15%] md:w-[105px] xl:w-[245px]",
  phillyImage2: "absolute left-0 top-[70px] md:top-[110px] xl:top-[350px] w-[15%] md:w-[105px] xl:w-[285px]",
  phillyImage3: "absolute right-0 top-[-15px] w-[15%] md:w-[95px] xl:w-[270px]",
  phillyImage4: "absolute right-0 top-[110px] md:top-[175px] xl:top-[544px] w-[15%] md:w-[105px] xl:w-[255px]",
}

export const videoSliderStyles = {
  container:
    "bg-darkBlue text-white py-50 lg:pt-[44px] lg:pb-40 phillyfivecards lg:relative ",
  sectionContainer: "md:pt-50 pb-30",
  cardsContainer:
    "lg:grid lg:grid-cols-4 py-15 justify-center align-center mx-auto lg:w-[1140px] mb-30",
  heading:
    "text-[40px] text-white text-center w-full lg:w-3/4 font-ProxNarrow lg:mx-auto px-15 [&>h2]:leading-[49px] tracking-wide:leading-[50px]",
  heading2: 
    "text-[39px] mt-[0.10em] mb-[0.5em]  text-center w-full lg:w-3/4 font-ProxBold  lg:mx-auto px-15 [&>h2]:leading-[49px] tracking-wide:leading-[50px]",
  description:
    "philyFiveCard philyFiveCrd items-center text-[18px] md:w-[955px] flex-col flex w-full  text-white px-auto mx-auto pb-20 text-center justify-center leading-30 px-10 tracking-[0]",
  description2:
    "philyFiveCrd items-center text-[18px] md:w-[955px] flex-col flex w-full  text-lightGrey lg:text-2xl px-auto mx-auto pb-20 text-center justify-center leading-30 px-10 tracking-[0]",
  btnWrpr: "flex items-center justify-center",
  btnContainer:
    "tab-btn bg-cover mx-[12px] flex justify-end items-center pb-25 font-bold flex-col gap-2 cursor-pointer  border-solid border cursor-pointer block text-[35px] leading-[23px] my-0 mx-auto text-center w-[320px] h-[387px] max-w-[100%] border-darkBlue  transition-all rounded-[30px] my-15 hover:border-white hover:border-solid hover:border hover:border text-white hover:text-white hover:bg-darkBlue event_button_click ",
  btnImg: "w-[135px]",
  sliderWrpr: "lg:overflow-hidden lg:w-[260px] w-[95%] mt-12 lg:p-auto rounded-[20px] relative",
  sliderImg: "mx-auto rounded-[20px] h-full  hover:scale-[1.05] transition-all duration-1000",
  icon:
    "absolute flex text-center leading-[29px] px-auto w-[50px] bottom-[220px] mx-auto justify-center z-[1]",
  videoHeading:
    "absolute inset-x-0 flex text-white font-ProxBold font-normal text-center leading-[29px] px-auto w-[228px] bottom-[25px] mx-auto justify-center z-[1] text-[25px]",
    gradTop: "absolute lg:bottom-[0px] bottom-[0]  w-[100%] rounded-20 h-[250px] bg-toTopGradientBlue"
}
