import React from 'react'
import {screen, render} from '@testing-library/react'
import SortMenu from '../SortMenu/index'
import { SortMenuProps } from '../../../mocks/SortMenu.mock'

describe('SortMenu', () => {

    test('should render select field', () => {
        render(<SortMenu {...SortMenuProps}/>)
    })

    test('Option count', () => {
        render(<SortMenu {...SortMenuProps}/>)
        expect(screen.getAllByRole('option').length).toBe(3)
    })

    test('Select Options', () => {
        render(<SortMenu {...SortMenuProps}/>)
        expect(screen.getAllByRole('option', { value: '1' }))
        expect(screen.getAllByRole('option', { value: '2' }))
        expect(screen.getAllByRole('option', { value: '3' }))
    })

})