export const defaultStyle = {
  btnstyle: "",
  icon:"",
  imgWrapper: ""
}

export const fullScreenMenu ={
  btnstyle:"bg-[#36236a] p-10 rounded-full my-4  mx-2 inline-block",
  icon:"bg-[#fff]  w-[25px]  h-[2px]  my-6 ",
  imgWrapper:"grid grid-cols-4  gap-20"

}