export const recipie ={
    heading:"The makings of the",
    subheading: "Smoked Haddock & Philly Mornay",
    recipeSteps :
      [
        {PreparationDescriptionItem:"Mix the panko breadcrumbs, parsley and parmesan together in a small bowl for later."},
        {PreparationDescriptionItem:"Place the milk, double cream and the smoked haddock fillets in a high sided pan ensuring the fish is covered by the liquid."},
        {PreparationDescriptionItem:"Heat on a low heat for 10 minutes, the liquid should be boiling gently by this point and the fish should be cooked through."},

      ],
    
    style :{
      stepsContainer: "stepsContainer flex flex-wrap my-50 px-20 lg:px-auto justify-center",
      olClass: "list-decimal w-full md:w-[58%] mx-auto",
      liDescription:
        "liDescription inline-flex items-center lg:w-5/6 w-3/4 ml-[20px] text-[18px] text-darkBlue",
      roundedHeading:
        "bg-liBgRound reciepeLi bg-contain bg-no-repeat lg:h-[80px] lg:w-[80px] h-[35px] w-[42px] font-bold lg:text-[48px] text-[30px] flex  items-center justify-center w-[10%]",
      receipeHeading:
        "receipeHeading mb-10 text-darkBlue text-[30px] font-semibold w-full mx-auto flex justify-center my-0 leading-[30px]",
      receipeSubheading:
        "subheading text-darkBlue text-[30px] font-semibold w-full justify-center mx-auto flex text-center my-0 leading-[30px]",
      receipeMainWrpr:
        "receipeMainWrpr lg:w-[1170px] w-full mx-auto flex flex-wrap",
        headingWrpr:"flex mx-auto flex-wrap mt-40"

    }
  }