import React from "react"

export default function Textarea(props) {
  const { label, id, name, rows, placeholder } = props

  return (
    <div>
      <div className="mb-3 xl:w-96">
        <label
          htmlFor={id}
          className="form-label inline-block mb-2 text-gray-700"
        >
          {label}
        </label>
        <textarea
          className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
          id={id}
          rows={rows}
          placeholder={placeholder}
          name={name}
        ></textarea>
      </div>
    </div>
  )
}

Textarea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.array,
}
