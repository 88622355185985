export const props = {
    "recipesList": [
        {
            "RecipeID": 398315,
            "RecipeName": "‘Smokin Bros’ dev Smoked Salmon, Philly and Chive Scones",
            "SeoPageName": "smokin-bros-smoked-salmon-philly-398315",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/398315_96.jpg"
            },
            "objectID": "398315",
            "_highlightResult": {
                "RecipeName": {
                    "value": "‘Smokin Bros’ dev Smoked Salmon, Philly and Chive Scones",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "parmesan cheese",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "chives",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "salt",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "self raising flour",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "cheddar",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "unsalted butter",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "egg",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "milk",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "lemon",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "salt",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "dill",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "smoked salmon",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "Place all scone ingredients in a mixer, except for the <b>milk</b> and <b>egg</b>, slowly paddle mix until it is a crumble texture.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Add the <b>egg</b> and <b>milk</b>, being careful not to over work. When the dough starts to come together take it off the mixer and finish by hand.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Roll out to 2 cm thick and cut with a 4-5 cm cutter. Once cut, place on parchment, <b>egg</b> wash and bake at 160 °C for 12-15 minutes.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Combine all <b>Philly</b> mix ingredients together with a spoon. Put in a piping bag ready for service.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Warm the scone in an oven at 180 °C for 3-4 minutes. Cut in half and place the <b>smoked salmon</b> on top. Generously pipe on the <b>Philly dill</b> mix and garnish with fresh <b>lemon zest</b> and sprig of <b>dill</b>.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 426310,
            "RecipeName": "Apple & Caramel Philly Turnovers",
            "SeoPageName": "apple-caramel-philly-turnovers-426310",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/426310_96.jpg"
            },
            "objectID": "426310",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Apple & Caramel Philly Turnovers",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "caster sugar",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "butter",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "double cream",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "double cream",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "vanilla extract",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "icing sugar",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "puff pastry",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "apples",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "egg yolks",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "milk",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "granulated sugar",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "The first job is to make the caramel sauce, take a heavy based saucepan and place the caster sugar in and place on a low to medium heat gently stirring when required to stop it burning. Once all the sugar is dissolved and you have a lovely caramel colour turn off the heat and add the butter whilst whisking until it has all melted and fully combined then add the double cream and whisk until you have a silky caramel sauce. Place the sauce into a bowl and allow to cool down.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "The second job is to make the filling, place the cheesecake mix ingredients into a stand mixer with the whisk attachment and whisk until smooth and stiff enough to hold its shape.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Peel, core and dice the apple into small chunks and then fold through the Philly mix.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Add 100g of your cooled caramel sauce and gently mix in to give a ripple effect.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "To make the turnovers, roll out the puff pastry to roughly 1cm thickness and then use a cereal bowl to cut out individual rounds of pastry.</p>",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Place a dessert spoon of filling into the centre of each round of pastry – making sure to leave enough room around the edges to seal the turnover.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Egg wash the edge of the pastry round and then fold over into a semi-circle – think of it like an empanada making sure to squeeze any air out and then press down with a fork to help seal the edges.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Make three small incisions on top of the turnover to help steam escape and then gently egg wash all over and place into the fridge for 10 minutes.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "After the 10 minutes repeat the egg wash and then sprinkle with the granulated sugar.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Place the turnovers onto a baking mat lined tray into a preheated oven at 200 °C with no fan and cook for 22 mins until golden brown.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Remove from the oven and place onto a cooling rack and allow to cool slightly before serving with some of the leftover caramel sauce and a scoop of vanilla ice cream.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 426299,
            "RecipeName": "Avocado Whipped Philly, Smashed Peas & Ham Bruschetta",
            "SeoPageName": "avocado-whipped-philly-smashed-peas-426299",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/426299_96.jpg"
            },
            "objectID": "426299",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Avocado Whipped Philly, Smashed Peas & Ham Bruschetta",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "avocado",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "peas",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "lemon rind",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "olive oil",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "parma ham",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "baguette",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "salt",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "black pepper",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "Blend the avocado with the zested lemon rind, olive oil and seasoning until smooth and whipped.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Add the Philly and pulse but do not over work.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Cook the peas in some simple salted water until soft and take out, season with salt and lemon juice then chop up coarsely.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Toast the baguette until very crispy.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Pipe (or spoon) the Philly avocado mix onto the baguette, scatter the peas over the top, add a twist of black pepper and then place the ham on top.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 398288,
            "RecipeName": "Blueberry and Philly Loaf Cake",
            "SeoPageName": "blueberry-philly-loaf-cake-398288",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/398288_96.jpg"
            },
            "objectID": "398288",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Blueberry and Philly Loaf Cake",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "unsalted butter",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "caster sugar",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "eggs",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "vanilla essence",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "self raising flour",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "salt",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "blueberries",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "butter",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "icing sugar",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "Pre-heat the oven to 175 °C. Line a loaf tin (240 x 140 x 80 mm) with <b>unsalted butter</b> and <b>self-raising flour</b> to prevent the loaf cake from sticking.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "With an electric whisk, beat the <b>butter</b> and <b>Philadelphia</b> together until smooth.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Whisk in the <b>caster sugar</b>, this should take approximately 5 minutes to ensure the batter becomes light and fluffy.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Slowly whisk in the <b>eggs</b>, one at a time, and then whisk in the <b>vanilla essence</b>.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "On a lower setting, gently whisk in sieved <b>self-raising flour</b> and <b>salt</b> until just combined.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Gently mix in the <b>blueberries</b> using a spoon – top tip – lightly toss the <b>blueberries</b> in <b>self-raising flour</b> to prevent the fruit from sinking.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Place the batter into the loaf tin and tap on the work surface to settle the batter and remove any air bubbles.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Place in the pre-heated oven on the middle shelf for 70 to 90 minutes. Check the loaf cake is baked by placing a knife in the center of the cake and it should come out clean. If the loaf begins to brown too much through cooking cover with tin foil.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Once baked, remove the loaf cake from the oven and allow to cool in the tin for 15 minutes, then cool thoroughly by removing the loaf cake from the tin and cooling on a wire rack completely.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "To make the frosting, whip the soft <b>butter</b> with the <b>icing sugar</b> until pale. Then fold in the <b>Philadelphia</b> and mix to combine. Spread evenly over the cake and allow to set, approximately 15 minutes, then serve.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 398282,
            "RecipeName": "Butternut Squash Philly Pasta",
            "SeoPageName": "butternut-squash-philly-pasta-398282",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/398282_96.jpg"
            },
            "objectID": "398282",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Butternut Squash Philly Pasta",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "carrots",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "butternut squash",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "sweet potato",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "leeks",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "oil",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "red onion",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "garlic",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "maple syrup",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "passata",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "vegetable stock",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "spaghetti",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Light",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "Roast the prepared <b>carrots</b>, <b>butternut squash</b>, <b>sweet potato</b> and leeks for 20 minutes at 180 °C.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Fry the <b>red onion</b> and <b>garlic</b> in the <b>oil</b> in a large pan. Add the roasted vegetables, <b>passata</b>, <b>vegetable stock</b> and <b>maple syrup</b>. Cover, and cook on a low heat for 30 minutes.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Blitz into a thick sauce and check seasoning.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Boil the <b>spaghetti</b> for the recommended time on the packet. Drain and fold through the hot sauce along with the <b>Philadelphia</b> to give a rich, creamy finish.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 398304,
            "RecipeName": "Cauli-Philly Cheese",
            "SeoPageName": "cauli-philly-cheese-398304",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/398304_96.jpg"
            },
            "objectID": "398304",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Cauli-Philly Cheese",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "cauliflower",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Light",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "English mustard",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Gruyère",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "lemon",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "breadcrumbs",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "chives",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "olive oil",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "salt",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "Cook the <b>cauliflower</b> in boiling water for 7 minutes or until just tender. Drain and allow to steam dry, save some of the cooking water.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Mix the <b>Philadelphia</b> with half of the <b>Gruyère</b>, <b>mustard</b>, <b>chives</b>, <b>lemon juice</b> and <b>zest</b> until smooth. Add 4 tbsp of the cooking water to loosen the mix and add in the cooked <b>cauliflower</b>, season with <b>salt</b> and <b>pepper</b> to taste.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Add all contents to a shallow oven proof dish, scatter over the <b>breadcrumbs</b> and remaining <b>cheese</b> and drizzle with <b>oil</b>.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Cook in an oven at 190 °C for 10-15 minutes or until the top is browned and crispy.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 398299,
            "RecipeName": "Chestnut Mushroom and Nutmeg Lasagne",
            "SeoPageName": "chestnut-mushroom-nutmeg-lasagne-398299",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/398299_96.jpg"
            },
            "objectID": "398299",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Chestnut Mushroom and Nutmeg Lasagne",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "olive oil",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "garlic",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "chestnut mushrooms",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "thyme",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "vegetable stock",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "nutmeg",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "paprika",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "hard cheese",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "lasagne sheets",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "Preheat an oven to 180 °C.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Heat the <b>olive</b> oil in a pan over a medium heat. Fry the <b>garlic</b> and <b>mushrooms</b> for 3 minutes until soft.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Stir the <b>thyme</b> and <b>Philadelphia</b> into the <b>mushrooms</b> and continue to cook for 1 minute until the <b>Philadelphia</b> has melted to create a sauce. Add 3 tsp of the grated <b>hard cheese</b> along with the <b>nutmeg</b> and <b>paprika</b>. Season to taste.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Put a quarter of the sauce into a baking dish and place 2 <b>lasagne sheets</b> on top. Repeat this until all the <b>lasagne sheets</b> have been used and you have 3 layers.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Finally, top with the remaining quarter of the sauce mix and sprinkle over the remaining <b>hard cheese</b>. Cook for 35 minutes or until the pasta is soft.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 398312,
            "RecipeName": "Chicken Breast with a Philly and Tarragon Sauce",
            "SeoPageName": "chicken-breast-a-philly-tarragon-398312",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/398312_96.jpg"
            },
            "objectID": "398312",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Chicken Breast with a Philly and Tarragon Sauce",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "chicken",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "sprigs of thyme",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "cloves garlic",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "butter",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "broccoli",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "baby carrots",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "baby leeks",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "potatoes",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "butter",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "cloves garlic",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "sprigs of thyme",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "lardons",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "silver skins",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "chestnut mushrooms",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "jus",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "tarragon",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "Cooking Chicken Breast<br>\r\nPlace an ovenproof pan on a medium heat, add a little oil and cook the <b>chicken breast</b> skin side first.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "When a lovely golden colour starts to form turn over. When it has a light golden colour all over, throw in the <b>butter</b>, <b>garlic</b> and sprig of <b>thyme</b> and cook in the oven at 180 °C for 12 minutes with the skin side down.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "When cooked remove the <b>chicken</b>, <b>garlic</b> and <b>thyme</b> but leave the juices in the pan.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Vegetables & Fondant Potato<br>\r\nPrepare and lightly blanch vegetables, making sure they are refreshed in ice water to help maintain their lovely fresh colours.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Prepare the <b>potato</b> by first choosing the ring size then finding the <b>potatoes</b> that will fit. Peel and top and tail the <b>potatoes</b> and with the palm of your hand push the ring over the <b>potato</b> to make a cylinder.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Trim the ends and make them all the same height. Keep in water ready for cooking and serving.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "When ready to serve, add <b>butter</b> to a pan at least 6 cm deep and cook the <b>potato</b> until golden all over and soft in the middle.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Sauce<br>\r\nIn a frying pan, on a medium heat, put a good drizzle of oil and a knob of <b>butter</b> and bring to a foam without colour. Add the <b>silver skins</b> and fry until they have a lovely even caramelisation, then set aside.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Put the pan with the <b>chicken</b> juices onto a gentle heat and add the halved <b>mushrooms</b>. When they are half cooked add the <b>lardons</b>. Just before you finish colouring the <b>lardons</b> and <b>mushroom</b>, add the <b>onions</b>.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "After 1-2 minutes, add 2 tbsp of <b>Philadelphia</b> and leave under lights - this will slowly allow the <b>Philly</b> to warm through. When the <b>Philly</b> is melted add 2 tbsp of <b>jus</b> and the chopped <b>tarragon</b> to finish sauce.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Serve<br>\r\nWhen everything is ready, refresh the vegetables and serve up all the elements of the dish.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        },
        {
            "RecipeID": 398310,
            "RecipeName": "Chicken, Smoked Ham Hock and Philly Suet Pie",
            "SeoPageName": "chicken-smoked-ham-hock-philly-398310",
            "PhotoURLAdditional": {
                "PhotoLink": "http://images.sweetauthoring.com/recipe/398310_96.jpg"
            },
            "objectID": "398310",
            "_highlightResult": {
                "RecipeName": {
                    "value": "Chicken, Smoked Ham Hock and Philly Suet Pie",
                    "matchLevel": "none",
                    "matchedWords": []
                },
                "IngredientList": [
                    {
                        "IngredientName": {
                            "value": "chicken legs",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "ham",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "onion",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "carrots",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "celery",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "cloves garlic",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "leek",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "butter",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "plain flour",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "ham",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "Philadelphia Original",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "tarragon",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "self raising flour",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "suet",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "salt",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    },
                    {
                        "IngredientName": {
                            "value": "water",
                            "matchLevel": "none",
                            "matchedWords": []
                        }
                    }
                ],
                "PreparationSteps": {
                    "PreparationTypes": [
                        {
                            "PreparationDescriptionItem": {
                                "value": "<b>Pie Filling</b><br>\r\nPlace the <b>ham hock</b> in a pan with the stock vegetables and cover in water.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Cling film the pan twice and then add two layers of tin foil. Braise in the oven overnight on 100 °C.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Once cooked, remove from the stock and leave to cool just enough that you can handle them, pick the meat off the bone, discarding the bones, skin and fat. Pass the cooking <b>stock</b> and leave to one side.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Season the skin of the <b>chicken legs</b> and roast at 190 °C for 45 minutes, or until cooked depending on the size of the legs. Once cooked, leave to cool and pick the meat off the bone, once again discarding the bones and skin.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Make the sauce for the pie by melting the <b>butter</b> in a pan, then add the <b>plain flour</b>, slowly adding the <b>stock</b>, whisking in between. Remove from the heat and add the <b>Philadelphia</b>, letting it melt slowly into the sauce.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Mix just enough sauce through the <b>ham hock</b> and <b>chicken</b> so that the meat is covered and finish with the <b>tarragon</b>. Put in the fridge to cool completely.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "<b>Suet Pastry</b><br>\r\nPlace all the dry ingredients into a stand mixer with the paddle attachment, turn the mixer on slow and start to add the water slowly. Bring to a dough, being careful not to over mix. You should be able to see specs of the suet throughout the dough.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Wrap in cling film and leave to rest in the fridge for at least an hour.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "<b>Building the Pie</b><br>\r\nGrease your desired mould (metal or plastic as the pies are steamed).",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Roll the pastry out to 4 mm, using a ring cutter bigger than the mould and line moulds leaving overhang around the edge.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Fill to the top with filling, then cut another disc of pastry for the lid and attach with water. Crimp edges, pierce a small hole in the top and trim off the excess pastry off.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Once all the pies are made, steam for 60 minutes and leave to cool in the fridge.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Once cooled, remove the pies from the mould.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "<b>Finishing</b><br>\r\nPut the pie on a lined tray and bake at 190 °C for 15-20 minutes until golden and the center is hot when probed.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        },
                        {
                            "PreparationDescriptionItem": {
                                "value": "Heat up your jus/sauce and serve the pie with summer vegetables, spinach and finish with jus over the top.",
                                "matchLevel": "none",
                                "matchedWords": []
                            }
                        }
                    ]
                }
            }
        }
    ]
}