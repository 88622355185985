export const linkButton = {
    "name": "Discover more - 10kg",
    "label": "Discover more",
    "title": "philadelphia-original-10kg",
    "target": "_self",
    "isAnchorTag": null,
    "scrollToElementId": null,
    "imageType": null,
    "url": "/range/philadelphia-original-10kg",
    "className":"text-sm buttonEffect2 recipeBtn event_internal_link"
    }