const query = (id, isPreview) => `{
  imageLayout(id: "${id}", preview: ${isPreview}) {
    heading
    description
    variant
    imagesCollection {
      items {
        gaEventClass
        gaEventLabel
        image {
          url
          title
          description
          width
          height
        }
        link {
          label
          title
          url
          target
          isAnchorTag
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            title
            description
            url
            width
            height
          }
        }
      }
    }
  }
}
`

module.exports = { query }
